// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.previewCustomCont{
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  max-width: 100%;
}

.customBtn{
  position: absolute;
  bottom: 80px;
  max-width: 130px;
}`, "",{"version":3,"sources":["webpack://./src/App/pages/previewcustomselection/PreviewCustomSelection.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,4BAA4B;EAC5B,kCAAkC;EAClC,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":[".previewCustomCont{\n  width: 100%;\n  height: 100vh;\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: center center;\n  position: relative;\n  max-width: 100%;\n}\n\n.customBtn{\n  position: absolute;\n  bottom: 80px;\n  max-width: 130px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
