import axios from 'axios';
import { useState, useEffect } from 'react';
import {useNavigate, Link, createSearchParams, useSearchParams} from 'react-router-dom';
import logo from '../../images/logo.png';
import {SignTypeItem} from '../../components/SignTypeItem'
import '../digitalsignlist/DigitalSignList.css'
import { Signin } from '../signin/Signin';

export const DigitalSignList = () => {
  console.log("In Digital Sign List page");

  const navigate = useNavigate();
  const usertoken = localStorage.getItem('appusertoken');
  const [queryParams] = useSearchParams();
  const dsCategory = queryParams.get('dsc');
  const [dsoList, setDSOListData] = useState([]);
  const [freeOrder,setFreeOrder] = useState('');

  useEffect(() => {
    const tokenAPI = localStorage.getItem('appusertoken');
    const headers = {
      'Content-Type': 'application/json',
      'Token': tokenAPI
    }
    let tbID = localStorage.getItem('appusertblid');
    let clID = localStorage.getItem('appcid');
    let firstOrderApi = process.env.REACT_APP_API_ENDPOINT+"client-app/is-first-order/"+tbID+"/"+clID;
    axios.get(firstOrderApi, {
      headers: headers
    })
    .then(res => {
      if(res.status === 200){
        if(res.data.first_order && res.data.first_order === true){
          setFreeOrder('FREE');
        }else{
          setFreeOrder('$');
        }
      }
    })
    .catch((error) => {
      if(error.response.status === 401){
        localStorage.clear();
        navigate({ pathname: '/' });
      }
    });

    let dsoListApi = process.env.REACT_APP_API_ENDPOINT+"client-app/get-digital-sign-list-club/"+dsCategory;
    axios.get(dsoListApi, {
      headers: headers
    })
    .then(res => {
      if(res.status === 200){
        if(res.data){
          setDSOListData(res.data.digital_signs);
        }
      }
    })
    .catch((error) => {
      if(error.response.status === 401){
        localStorage.clear();
        navigate({ pathname: '/' });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectDSO = (index) => {
    if(index !== '' && dsoList.length > index){
      navigate({
        pathname: '/preview-selection',
        search: createSearchParams({
          dso: dsoList[index].id
        }).toString()
      });
    }
  }

  return (
    <>
    {usertoken ? 
      <div className="App-page">
          <section className="topSection">
            <img src={logo} className="App-logo-medium" alt="logo" /><br/>
            <span className="backLink"><Link to="/digital-sign"></Link></span>
          </section>
          { dsoList.length ?
            <section className="bottomSection">
              <label>Select an option<br/> from the list</label><br/>
              <div className="signBoxListCont">
                {
                  dsoList.map((item,i) =>
                    <SignTypeItem key={i} index={i} click={selectDSO} class="signBoxSmall" customclass="" image={(item.thumb_url !== '')?item.thumb_url:item.image_url} cost={freeOrder !== '$'?freeOrder:item.currency + item.price}></SignTypeItem>
                  )
                }
              </div>
            </section>
            :
            <section className="bottomSection"></section>
          }
      </div>
      :
      <Signin/>
    }
    </>
  );
}