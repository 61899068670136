
export const UploadingProgressBar = (props) => {
  return (
    <>
       <div className="progressBarCont">
            <div className="progressBarRail" style={{width:''+props.progressBarValue+'%'}}>
              <span className="progressBarText"> {props.progressBarValue}% </span>
            </div>
        </div>
    </>
  );
}