import axios from 'axios';
import { useState, useEffect } from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import logo from '../../images/logo.png';
import './Report.css';
import { Login } from "../../pages/login/Login";
import { ReportListItem } from '../../components/ReportListItem';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const Report = () => {
  console.log("In Report page");
  const navigate = useNavigate();
  let params = useParams();
  const [reportData, setReportData] = useState([]);
  const token = localStorage.getItem('usertoken');
  const userRole = localStorage.getItem('userrole');
  const [filterState, setFilterState] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [checkMidnight, setCheckMidnight] = useState(false);
  const [checkNotCompleted, setCheckNotCompleted] = useState(false);

  const getReportsData = () => {
    let reportsApi = process.env.REACT_APP_API_ENDPOINT+"admin/table-requests/between-dates";
    const tokenAPI = localStorage.getItem('usertoken');
    const headers = {
      'Content-Type': 'application/json',
      'Token': tokenAPI
    }
    axios.put(reportsApi, { start_date: formatDate(startDate), end_date: formatDate(endDate), midnight: checkMidnight, incomplete: checkNotCompleted},
    { 
      headers: headers
    })
    .then(res => {
      if(res.status === 200){
        if(res){
          setReportData(res.data);
        }else{
          setReportData([]);
        }
        
      }
    })
    .catch((error) => {
      setReportData([]);
      if(error.response.status === 401 || error.response.status === 400){
        localStorage.removeItem('usertoken');
        navigate({ pathname: '/admin' });
      }
    });
  }

  function formatDate(date = new Date()) {
    var day, month, year;
    year = date.getFullYear();
    month = date.getMonth() + 1;
    day = date.getDate();
    
    if (month < 10) {
      month = '0' + month;
    }
    if (day < 10) {
      day = '0' + day;
    }
    return month + '/' + day + '/' + year;
  }

  useEffect(() => {
    getReportsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showFilters = (e) => {
    setFilterState(true);
  }

  const hideFilters = (e) => {
    setFilterState(false);
    getReportsData();
  }

  const handleMNCheckboxChange = () => {
      setCheckMidnight(!checkMidnight);
  };

  const handleICCheckboxChange = () => {
    setCheckNotCompleted(!checkNotCompleted);
};

  let clubName = (params.name)?params.name:'';
  let totalAmount = 0;
  let totalCompletedCount = 0;
  if(reportData){
    reportData.forEach(listingItem => {
      listingItem.statusLabel = '';
      if(listingItem.status === 1){
        listingItem.statusLabel = 'Pending';
      }else if(listingItem.status === 2){
        listingItem.statusLabel = 'In-Progress';
      }else if(listingItem.status === 3){
        totalAmount += listingItem.payment;
        totalCompletedCount++;
        listingItem.statusLabel = 'Completed';
      }else if(listingItem.status === 4){
        listingItem.statusLabel = 'Rejected';
      }else if(listingItem.status === 5){
        listingItem.statusLabel = 'Playing';
      }
    });
  }

  return (
    <>
      { token && userRole != 2 ?
      <div className="Admin-page">
        <section className='topSection'>
          <img src={logo} className="Admin-logo-medium" alt="logo" />
          <span className="backLink"><Link to="/dashboard"></Link></span>
          <p>{clubName}</p>
        </section>
        <section className='bottomSection reportsCont'>
          <p>Report<strong>
            {(formatDate(startDate) === formatDate(endDate))? 
            new Intl.DateTimeFormat('default', {weekday:'short', day: '2-digit', month: 'short'}).format(startDate)
            :
            new Intl.DateTimeFormat('default', {day: '2-digit', month: 'short'}).format(startDate) +" - "+ new Intl.DateTimeFormat('default', {day: '2-digit', month: 'short'}).format(endDate)
            } 
          <span id="clientAppReportsFilter" className='calender' onClick={showFilters}></span></strong></p><hr/>
          <table cellSpacing="13">
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>Table</th>
                <th>Date & Time</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {
                reportData ? <ReportListItem data={reportData} /> : <tr><td colSpan="4" >No data available</td></tr>
              }
            </tbody>
          </table><br/>
          <hr/>
          <p>Total {(totalCompletedCount > 0)?"("+totalCompletedCount+")":""} <strong className='whiteText'>${totalAmount}</strong></p>
        </section>
        {filterState ?
          <div className='filterOverlayReports'>
            <div className='calenderCont'>
              <label>Date Range</label>
              <DatePicker open selected={startDate} onChange={(date) => setStartDate(date)} selectsStart startDate={startDate} endDate={endDate} maxDate={new Date()} shouldCloseOnSelect={false} showPopperArrow={false} calendarStartDay={1} />
              <small>TO</small>
              <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} selectsEnd startDate={startDate} endDate={endDate} minDate={startDate} maxDate={new Date()} shouldCloseOnSelect={false} showPopperArrow={false} calendarStartDay={1} />
              <button id="clientAppReportsSearch" onClick={hideFilters}><span className='searchIcon'></span></button>
              <div className='checkboxCont'>
                <input id="clientAppReportsMidNightFilter" checked={checkMidnight} onChange={handleMNCheckboxChange} type='checkbox' /><span>Include orders past midnight</span><br/>
                <input id="clientAppReportsNonCompletedFilter" checked={checkNotCompleted} onChange={handleICCheckboxChange} type='checkbox' /><span>Include non-completed orders</span>
              </div>
            </div>
          </div>
          : <div></div>
        }
      </div>
      :
      <Login />
      }
    </>
  );
}