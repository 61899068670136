import axios from 'axios';
import { useState, useRef, useEffect } from 'react';
import {useNavigate, useSearchParams, createSearchParams, Link} from 'react-router-dom';
import './Customize.css';
import { HexColorPicker } from "react-colorful";
import { Signin } from '../signin/Signin';
import Draggable from 'react-draggable';

const DEFAULT_FILTER_OPTIONS = [
  {
    name: 'Font Family',
    property:'fontFamily',
    value:'Open Sans',
    class: 'btn1'
  },
  {
    name: 'Font Size',
    property:'fontSize',
    value:'5vh',
    class: 'btn6'
  },
  {
    name: 'Text Color',
    property:'color',
    value:'#FFFFFF',
    class: 'btn4'
  },
  {
    name: 'Background Color',
    property:'backgroundColor',
    value:'unset',
    class: 'btn5'
  },
  {
    name: 'Vertical Align',
    property:'top',
    value:'49%',
    class: 'btn2'
  },
  {
    name: 'Text Alignment',
    property:'textAlign',
    value:'center',
    class: 'btn3'
  }
]

const DEFAULT_FONT_OPTIONS = [
  {
    value:'Open Sans',
    class: 'fontBtn1'
  },
  {
    value:'Young Serif',
    class: 'fontBtn2'
  },
  {
    value:'Dancing Script',
    class: 'fontBtn3'
  },
  {
    value:'Caveat',
    class: 'fontBtn4'
  },
  {
    value:'Archivo Black',
    class: 'fontBtn5'
  },
  {
    value:'Bebas Neue',
    class: 'fontBtn6'
  }
]

const DEFAULT_VERTICAL_ALIGN = [
  {
    property:'top',
    value:'8%',
    class: 'vaBtn1'
  },
  {
    property:'top',
    value:'50%',
    class: 'vaBtn2'
  },
  {
    property:'bottom',
    value:'8%',
    class: 'vaBtn3'
  },
  {
    property:'top',
    value:'49%',
    class: 'vaBtnCustom'
  }
]

const DEFAULT_TEXT_ALIGN = [
  {
    value:'left',
    class: 'taBtn1'
  },
  {
    value:'center',
    class: 'taBtn2'
  },
  {
    value:'right',
    class: 'taBtn3'
  }
]

const DEFAULT_TEXT_COLORS = [
  {
    value:'#ff0000',
    class: 'colorred'
  },
  {
    value:'#b1ddf3',
    class: 'colorblue'
  },
  {
    value:'#165E0B',
    class: 'colorgreen'
  },
  {
    value:'#fcba03',
    class: 'colororange'
  },
  {
    value:'#FF2EAA',
    class: 'colorpink'
  }
]

const DEFAULT_TEXT_BACKGROUND = [
  {
    value:'transparent',
    class: 'bgcolortransparent',
    textEffect: '0px 0px 0px transparent'
  },
  {
    value:'rgba(0,0,0,0.5)',
    class: 'bgcolorblack',
    textEffect: '0px 0px 1px #f2f2f2, 0px 0px 1px #f2f2f2, 0px 0px 1px #f2f2f2, 0px 0px 1px #f2f2f2'
  },
  {
    value:'rgba(255,255,255,0.5)',
    class: 'bgcolorwhite',
    textEffect: '0px 0px 1px #002133, 0px 0px 1px #002133, 0px 0px 1px #002133, 0px 0px 1px #002133'
  }
]

export const Customize = () => {
 console.log("In Customize page");
 
 const filterOptions = DEFAULT_FILTER_OPTIONS;
 const [selectedOptionIndex, setSelectedOptionIndex] = useState();

 const usertoken = localStorage.getItem('appusertoken');
 const navigate = useNavigate();
 const [queryParams] = useSearchParams();
 const mo = queryParams.get('mo');
 const custom = queryParams.get('c');
 const mode = queryParams.get('mode');
 const editableBid = (queryParams.get('bid')?queryParams.get('bid'):null);
 const [mediaData, setMediaData] = useState([]);
 const [pageState, setPageState] = useState(1);
 const [refreshState, setRefreshState] = useState(0);
 const fontOptions = DEFAULT_FONT_OPTIONS;
 let vaOptions = DEFAULT_VERTICAL_ALIGN;
 const taOptions = DEFAULT_TEXT_ALIGN;
 const colorOptions = DEFAULT_TEXT_COLORS;
 const backgroundcolorOptions = DEFAULT_TEXT_BACKGROUND;
 const [selectedFontOptionIndex, setSelectedFontOptionIndex] = useState(0);
 const [selectedVAOptionIndex, setSelectedVAOptionIndex] = useState(3);
 const [selectedTAOptionIndex, setSelectedTAOptionIndex] = useState(1);
 const [selectedColorOptionIndex, setSelectedColorOptionIndex] = useState();
 const [selectedBackgroundColorOptionIndex, setSelectedBackgroundColorOptionIndex] = useState(0);

 const [textContent, setTextContent] = useState('');
 const textareaHeight = useRef('auto');
//  const textareaTPosition = (vaOptions[selectedVAOptionIndex].property === 'top')?"0px":"unset";
//  const textareaBPosition = (vaOptions[selectedVAOptionIndex].property === 'bottom')?"0px":"unset";
 const maxContHeightPX = (Math.round(window.innerHeight / (100 / 80)));
 const backgroundColorFilterValue = backgroundcolorOptions[selectedBackgroundColorOptionIndex].value;
 const backgroundColorFilterTextEffect = backgroundcolorOptions[selectedBackgroundColorOptionIndex].textEffect;
 const [customcolor, setCustomColor] = useState("#ffffff");
 const textareaRef = useRef(null);
 const dragHeightBounds = parseInt(window.innerHeight/2 - window.innerHeight*0.05);
 const dragWidthBounds = parseInt(window.innerWidth/2 - window.innerWidth*0.15);
 const [customColorView, setCustomColorView] = useState(false);
//  const clickTimeRef = useRef(0);
 const textareaLeft = useRef('10%');
 const [fontsize, setFontSize] = useState(5);

 const textBoxesArr = useRef([]);
 const [selectedTextBoxIndex, setselectedTextBoxIndex] = useState('');
 const [dragging, setDragging] = useState(false);
 const [textareaFocusState, setTextareaFocusState] = useState(false);
 const oldDragPosition = useRef({top:'0%', left:'0%'});
 const [newTextBoxCounter, setNewTextBoxCounter] = useState(0);
 const windowHTextareaView = window.innerHeight - 400;
 const dragXYRef = useRef(null);
 const trashRef = useRef(null);
 const [isIntersectTrash,setIntersectTrash] = useState(false);

 useEffect(() => {
  if(mode === 'edit' && editableBid !== null){
    const tokenAPI = localStorage.getItem('appusertoken');
    let orderDetailsApi = process.env.REACT_APP_API_ENDPOINT+"client-app/get-rejected-order-detail/"+editableBid;
    const headers = {
      'Content-Type': 'application/json',
      'Token': tokenAPI
    }
    axios.get(orderDetailsApi, {
      headers: headers
    })
    .then(res => {
      if(res.status === 200){
        if(res.data){
          let apiBookingData = res.data.rejected_order_details.BookingDetails;
          let mediaDataObj = {
            "currency": "$",
            "hls_url": apiBookingData.HLSUrl,
            "id": null,
            "mime": (apiBookingData.RequestType === 102?"video/mp4":"image/jpeg"),
            "price": apiBookingData.RequestPayment,
            "url": apiBookingData.RequestMedia,
            "type": (apiBookingData.RequestType === 102?3:2)
          }
          if(!mo){
            setMediaData(mediaDataObj);
          }
          if(apiBookingData.RequestAttribs){
            let textStyles = JSON.parse(apiBookingData.RequestAttribs);
            if(textStyles.data){  // has multiple textboxes
              if(textStyles.data.length){ 
                textBoxesArr.current = textStyles.data;
              }
            }else if (Object.keys(textStyles).length){
              if(!textStyles.textareaHeight){
                textStyles.textareaHeight = 'auto';
              }
              if(!textStyles.keyCount){
                textStyles.keyCount = 0;
              }
              textBoxesArr.current.push(textStyles);
            }
                let xyArr = [];
                for(let l=0; l<textBoxesArr.current.length; l++){
                  let wh50 = window.innerHeight*0.46;
                  let ht = window.innerHeight*parseInt(textBoxesArr.current[l].parentStyles.top.replace('%',''))/100;
                  let wt = window.innerWidth*(parseInt(textBoxesArr.current[l].parentStyles.left.replace('%',''))-10)/100;
                  xyArr.push({x:wt,y:ht-wh50});
                }
                dragXYRef.current =  xyArr; 
                setRefreshState(!refreshState);
          }
        }
      }
    })
    .catch((error) => {
      if(error.response.status === 401){
        localStorage.clear();
        navigate({ pathname: '/' });
      }
    });
  }

  if(!mo){return;}
  const tokenAPI = localStorage.getItem('appusertoken');
  let moPreviewApi = process.env.REACT_APP_API_ENDPOINT+"client-app/"+((custom === "1")?"get-custom-media/":"get-single-digital-object-club/")+mo;
  const headers = {
    'Content-Type': 'application/json',
    'Token': tokenAPI
  }
  axios.get(moPreviewApi, {
    headers: headers
  })
  .then(res => {
    if(res.status === 200){
      if(res.data){
        if(custom === "1" && res.data.custom_media_details){
          setMediaData(res.data.custom_media_details);
        }else if(res.data.digital_sign){
          setMediaData(res.data.digital_sign);
        }
      }
    }
  })
  .catch((error) => {
    if(error.response.status === 401){
      localStorage.clear();
      navigate({ pathname: '/' });
    }
  });
  resetFilters(); // when someone comes again on this page for other orders
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

  function resetFilters(){
    filterOptions.forEach(option => {
        if(option.property === 'color'){
            option.value = '#FFFFFF';
        }else if(option.property === 'fontFamily'){
            option.value = 'Open Sans';
        }else if(option.property === 'textAlign'){
            option.value = 'center';
        }else if(option.property === 'fontSize'){
            option.value = '5vh';
        }
    });
  }

  function updateFilters(index){
    if(parseInt(index) >= 0 && textBoxesArr.current.length > index){
      let getCurrentStyles = textBoxesArr.current[index];
      console.log(getCurrentStyles);
      let parentStyles = getCurrentStyles.parentStyles;
      if(parentStyles){
        if(parentStyles.fontSize){  // Set Font
          setFontSize(parseInt(parentStyles.fontSize.replace('vh','')));
        }
        if(parentStyles.color){  // Set text color
          var flag = false;
          colorOptions.forEach((color,j) => {
            if(parentStyles.color === color.value){
              setSelectedColorOptionIndex(j);
              flag = true;
              return;
            }
          });
          if(!flag){ // custom color case
            setSelectedColorOptionIndex(6);
          }
          filterOptions.forEach(option => {
            if(option.name === 'Text Color'){
                option.value = parentStyles.color;
            }
          })
        }
        if(parentStyles.fontFamily){  // Set font family
          fontOptions.forEach((font,j) => {
            if(parentStyles.fontFamily === font.value){
              setSelectedFontOptionIndex(j);
              return;
            }
          });
          filterOptions.forEach(option => {
            if(option.name === 'fontFamily'){
                option.value = parentStyles.fontFamily;
            }
          })
        }
        if(parentStyles.textAlign){  // Set text align
          taOptions.forEach((talign,j) => {
            if(parentStyles.textAlign === talign.value){
              setSelectedTAOptionIndex(j);
              return;
            }
          });
          filterOptions.forEach(option => {
            if(option.name === 'textAlign'){
                option.value = parentStyles.textAlign;
            }
          })
        }
      }
    }
  }
 
 function getFilterDefaultStyles(){
    const filters = {};
    filterOptions.map(option => {
      // reset to defaults
      if(option.property === 'color'){
          option.value = '#FFFFFF';
      }else if(option.property === 'fontFamily'){
          option.value = 'Open Sans';
      }else if(option.property === 'textAlign'){
          option.value = 'center';
      }else if(option.property === 'fontSize'){
          option.value = '5vh';
      }
      return(filters[`${option.property}`] = option.value)
    })
    return filters;
 }

 function getFilterPropsForAllTextBoxesAPI(){
    const allTextboxesFilterProps = textBoxesArr.current;
    if(allTextboxesFilterProps.length){
      for(let l=0; l<allTextboxesFilterProps.length; l++){
        let textContentHTML = allTextboxesFilterProps[l].text.replace(/\r\n|\r|\n/g,"<br />");  // Convert new lines to html tags
        allTextboxesFilterProps[l].text = textContentHTML;
        if(allTextboxesFilterProps[l].parentStyles.top){ // recalculate top before submit to avoid differences
          let captionTopWithScroll = (parseInt((document.getElementById('captionContID'+l).getBoundingClientRect().y + window.scrollY)*100/window.innerHeight));  // add scroll Y too to avoid differences
          if(captionTopWithScroll > 99){captionTopWithScroll = 99;}
          allTextboxesFilterProps[l].parentStyles.top = captionTopWithScroll + '%';
          if(document.getElementById('mediaContDiv')){
            var mediaObjDimensions = document.getElementById('mediaContDiv').getBoundingClientRect();
            allTextboxesFilterProps[l].mediaObjDimensions = mediaObjDimensions;
            let relativeTop = (parseInt(((document.getElementById('captionContID'+l).getBoundingClientRect().y + window.scrollY) - (mediaObjDimensions.y + window.scrollY))));
            allTextboxesFilterProps[l].relativeTop = ((relativeTop > 0)?relativeTop:0)+'px';
          }
        }
      }
    }
    const textboxesJson = {data:allTextboxesFilterProps};
    return textboxesJson;
 }

 const updateFont = (i) => {
    setSelectedFontOptionIndex(i);
    let selectedFontValue = '';
    fontOptions.forEach((font,j) => {
      if(i === j){
        selectedFontValue = font.value;
      }
    });
    if(selectedFontValue !== ''){
        filterOptions.forEach(option => {
          if(option.name === 'Font Family'){
              option.value = selectedFontValue;
          }
        })
        textBoxesArr.current[selectedTextBoxIndex].parentStyles.fontFamily = selectedFontValue;
    }
    updateTextareaHeightonFontChange();
 }

 const updateVAlign = (i) => {
  let selectedVAProp = '';
  let selectedVAValue = '';
  vaOptions.forEach((valign,j) => {
    if(i === j){
      selectedVAProp = valign.property;
      selectedVAValue = valign.value;
    }
  });
  if(selectedVAValue !== ''){
      filterOptions.forEach(option => {
        if(option.name === 'Vertical Align'){
            option.property = selectedVAProp;
            option.value = selectedVAValue;
        }
      })
      if(i !== 3){
        if(selectedVAProp === 'top'){
          textBoxesArr.current[selectedTextBoxIndex].parentStyles.top = selectedVAValue;
          textBoxesArr.current[selectedTextBoxIndex].parentStyles.bottom = 'unset';
        }else{
          textBoxesArr.current[selectedTextBoxIndex].parentStyles.top = 'unset';
          textBoxesArr.current[selectedTextBoxIndex].parentStyles.bottom = selectedVAValue;
        }
        if(selectedVAValue === '50%'){
          let textareaHeightNumber = parseInt(textBoxesArr.current[selectedTextBoxIndex].textareaHeight.replace('px',''));
          textBoxesArr.current[selectedTextBoxIndex].parentStyles.marginTop = ((textareaHeightNumber <= maxContHeightPX)?-textareaHeightNumber/2 + 'px':-maxContHeightPX/2 + 'px');
        }else{
          textBoxesArr.current[selectedTextBoxIndex].parentStyles.marginTop = '0px';
        }
      }
  }
  setSelectedVAOptionIndex(i);
}

const updateTAlign = (i) => {
  setSelectedTAOptionIndex(i);
  let selectedTAValue = '';
  taOptions.forEach((talign,j) => {
    if(i === j){
      selectedTAValue = talign.value;
    }
  });
  if(selectedTAValue !== ''){
      filterOptions.forEach(option => {
        if(option.name === 'Text Alignment'){
            option.value = selectedTAValue;
        }
      })
      textBoxesArr.current[selectedTextBoxIndex].parentStyles.textAlign = selectedTAValue;
  }
}

const updateTextColor = (i,c) => {
  setSelectedColorOptionIndex(i);
  let selectedColorValue = '';
  if(i === 6){
    selectedColorValue = c;
    setCustomColor(c);
  }else{
    colorOptions.forEach((color,j) => {
      if(i === j){
        selectedColorValue = color.value;
      }
    });
  }

  if(selectedColorValue !== ''){
      filterOptions.forEach(option => {
        if(option.name === 'Text Color'){
            option.value = selectedColorValue;
        }
      })
      textBoxesArr.current[selectedTextBoxIndex].parentStyles.color = selectedColorValue;
  }
}

 const updateText = (e) => {
    setTextContent(e.target.value); 
    textBoxesArr.current[selectedTextBoxIndex].text = e.target.value;
    // Update textarea height based on content height
    e.target.style.height = 'auto';
    e.target.style.height = e.target.scrollHeight +'px';
    textareaHeight.current = e.target.scrollHeight;
    textBoxesArr.current[selectedTextBoxIndex].textareaHeight = e.target.scrollHeight +'px';
 }

 const showCustomColorView = (i) => {
    setCustomColorView(!customColorView);
    setSelectedColorOptionIndex(6);
}

 const nextBtnClick = () => {
    setPageState(2);
 }

 const dragEvent = (e) => {
  console.log('Dragging.......');
    
    let captionContEle = "captionContID" + selectedTextBoxIndex;
    let captionTextEle = "captionText" + selectedTextBoxIndex;
    let draggedLeft = (parseInt(document.getElementById(captionContEle).getBoundingClientRect().x*100/window.innerWidth));
    let draggedTop = (parseInt(document.getElementById(captionContEle).getBoundingClientRect().y*100/window.innerHeight));
    if(oldDragPosition.current.top !== draggedTop+'%' || oldDragPosition.current.left !== draggedLeft+'%'){
      setDragging(true);
    }
    if(draggedTop > 97){draggedTop = 97;}
    // console.log("draggedTop >> "+draggedTop);
    // console.log("draggedLeft >> "+draggedLeft);
    vaOptions[3].value = draggedTop+'%';
    textareaLeft.current = draggedLeft+'%';
    textBoxesArr.current[selectedTextBoxIndex].parentStyles.top = draggedTop+'%';
    textBoxesArr.current[selectedTextBoxIndex].parentStyles.left = draggedLeft+'%';
    textBoxesArr.current[selectedTextBoxIndex].parentStyles.bottom = 'unset';
    const trashElement = trashRef.current;
    const trashRect = trashElement && trashElement.getBoundingClientRect();
    // Get the dragged element's bounding rectangle
    const draggedElementRect = document.getElementById(captionTextEle).getBoundingClientRect(); //e.target.getBoundingClientRect();
    // Check for intersection between the dragged element and the trash icon
    if(!trashRect || !draggedElementRect) {
      return;
    }
    if(!e.targetTouches) {
      var intersectsTrash =
      e.clientX < trashRect.right &&
      e.clientX > trashRect.left &&
      e.clientY < trashRect.bottom &&
      e.clientY > trashRect.top;
    } else {
      var intersectsTrash =
      e.targetTouches[0].clientX < trashRect.right &&
      e.targetTouches[0].clientX > trashRect.left &&
      e.targetTouches[0].clientY < trashRect.bottom &&
      e.targetTouches[0].clientY > trashRect.top;
    }
    if(intersectsTrash) {
      setIntersectTrash(true);
    } else {
      setIntersectTrash(false);
    }
    updateVAlign(3);
 }

 const handleStop = (e, index) => {
  console.log('Drag end.......');
  if(dragging){
    setDragging(false);
    if (isIntersectTrash) {
      // Intersection occurred, perform your desired action here
      if(textBoxesArr.current.length && index < textBoxesArr.current.length){
        textBoxesArr.current[index].text = '';
        setselectedTextBoxIndex(index);
        document.getElementById("captionContID"+index).style.transform = "unset";
      }
      setIntersectTrash(false);
    }
  }else{ // click event as there is no dragging
    console.log('Click event calling....');
    captionClicks(index);
  }  
};

 const dragStartEvent = (index) => {
  console.log('Drag start.......');
  setselectedTextBoxIndex(index);
  setDragging(false);
  oldDragPosition.current.top = textBoxesArr.current[index].parentStyles.top;
  oldDragPosition.current.left = textBoxesArr.current[index].parentStyles.left;
 }

 const handleBottomBtnsClick = (event) => {
    if(event === 'edit'){
      setPageState(1);
    }else if(event === 'confirm'){
      let confirmApi = process.env.REACT_APP_API_ENDPOINT+"client-app/save-customization";
      const headers = {
        'Content-Type': 'application/json',
        'Token': usertoken
      }
      const data = {
        //styles: getFilterPropsAPI(),
        styles: getFilterPropsForAllTextBoxesAPI(),
        tableid: localStorage.getItem('appusertblid'),
        requestmedia: mediaData.url,
        currency: mediaData.currency,
        price: mediaData.price,
        sign_id: mediaData.id,
        custom: parseInt(custom),
        requestmediatype: (((mediaData.mime).indexOf('video') > -1)?102:101),
        booking_id: editableBid
      };
      
      axios.post(confirmApi, data, {
        headers: headers
      })
      .then(res => {
        if(res.status === 200){
          if(mode === 'edit' && editableBid === res.data.booking_id){
            navigate({ pathname: '/update-success' });
          }else if(res.data && res.data.booking_id){
            navigate({
              pathname: '/review-order',
              search: createSearchParams({
                b_id: res.data.booking_id,
                mo: mo,
                c: custom
              }).toString()
            });
          }
        }
      })
      .catch((error) => {
        if(error.response.status === 401){
          localStorage.clear();
          navigate({ pathname: '/' });
        }
      });

    }
 }

 function createHTMLContent(index){
  let textContentHTML = textBoxesArr.current[index].text.replace(/\r\n|\r|\n/g,"<br />");  // Convert new lines to html tags
  return {__html: textContentHTML}
 }

  useEffect(() => {
    if(textareaRef.current && selectedOptionIndex === 0){
      textareaRef.current.focus();
    }
  }, [selectedOptionIndex]);

  const handleBodyClick = (e) => {
    var parentEl = document.querySelector('#colorpicker');
    if(parentEl && !parentEl.contains(e.target) && customColorView){
      setCustomColorView(false);
    }
    if(e.target.className === 'filterOverlay' && selectedOptionIndex === 0){
      setSelectedOptionIndex('');
    }
  }

  const captionClicks = (index) => {
    // if(!clickTimeRef.current){
    //   clickTimeRef.current = e.timeStamp;
    // }else{
    //   if(e.timeStamp - clickTimeRef.current > 0 && e.timeStamp - clickTimeRef.current < 300){ // double click/touch
    //       setSelectedOptionIndex(0);
    //       textareaRef.current.focus();
    //       clickTimeRef.current = 0;
    //   }else{
    //     clickTimeRef.current = e.timeStamp;
    //   }
    // }

    if(pageState == 2){
      return;
    }
    
    setSelectedOptionIndex('');
    setTextareaFocusState(false);
    setselectedTextBoxIndex(index);
    updateFilters(index);

    if(dragging || (oldDragPosition.current.left !== textBoxesArr.current[selectedTextBoxIndex].parentStyles.left || oldDragPosition.current.top !== textBoxesArr.current[selectedTextBoxIndex].parentStyles.top)){
      setDragging(false);
      return;
    }
    
    setSelectedOptionIndex(0);
    textareaRef.current.focus();
  }

  const textareaClicks = (e,index) => {
    // if(!clickTimeRef.current){
    //   clickTimeRef.current = e.timeStamp;
    // }else{
    //   if(e.timeStamp - clickTimeRef.current > 0 && e.timeStamp - clickTimeRef.current < 300){ // double click/touch
    //       textareaRef.current.focus();
    //       clickTimeRef.current = 0;
    //   }else{
    //     clickTimeRef.current = e.timeStamp;
    //   }
    // }
    setselectedTextBoxIndex(index);
  }

  const updateFontSize = (e) => {
    if(e.target.value !== ''){
        filterOptions.forEach(option => {
          if(option.name === 'Font Size'){
              option.value = e.target.value +'vh';
          }
        })
        textBoxesArr.current[selectedTextBoxIndex].parentStyles.fontSize = e.target.value +'vh';
    }
    let captionTextEle = document.getElementById('captionText' + selectedTextBoxIndex);
    textBoxesArr.current[selectedTextBoxIndex].textareaHeight = captionTextEle.offsetHeight +'px';    
    setFontSize(e.target.value);
  }

  const updateTextareaHeightonFontChange = () => {
    let textInputEle = 'textInput' + selectedTextBoxIndex;
    var textareaEle = document.getElementById(textInputEle);
    if(textareaEle){
      setTimeout(function(){
        textareaEle = textareaEle[0];
        if(textareaEle){
          textareaEle.style.height = textareaEle.scrollHeight +'px';
          textareaHeight.current = textareaEle.scrollHeight;
          textBoxesArr.current[selectedTextBoxIndex].textareaHeight = textareaEle.scrollHeight +'px';
        }
      },100);
    }
  }

  const selectFilter = (index) => {
    removeEmptyTextBox();
    if(index === 0 && textBoxesArr.current.length < 3){
      createNewTextBox();
    }
    setSelectedOptionIndex(index);
    if(index === 0){
      setTimeout(function(){
        textareaRef.current.focus(); 
      },250);
    }
  }

  function createNewTextBox(){
    setNewTextBoxCounter(newTextBoxCounter + 1);
    setTimeout(function(){
      textBoxesArr.current.push({keyCount:newTextBoxCounter,text:"",parentStyles:getFilterDefaultStyles(),childStyles:{backgroundColor: backgroundColorFilterValue, textShadow: backgroundColorFilterTextEffect},textareaHeight:'auto'});
      textBoxesArr.current[textBoxesArr.current.length - 1].parentStyles.top = '49%';
      textBoxesArr.current[textBoxesArr.current.length - 1].parentStyles.left = '10%';
      textBoxesArr.current[textBoxesArr.current.length - 1].parentStyles.marginTop = '0px';
      setselectedTextBoxIndex(textBoxesArr.current.length - 1);
      updateFilters(textBoxesArr.current.length - 1);
    },50);
  }

  function removeEmptyTextBox(){
    let indexToRemove = [];
    const allTextboxesData = textBoxesArr.current;
    if(allTextboxesData.length){
        for(let l=0; l<allTextboxesData.length; l++){
          if(allTextboxesData[l].text === ''){
            indexToRemove.push(l);
          }
      }
    }
    if(indexToRemove.length){
      for(let d=0; d<indexToRemove.length; d++){
        let removeEleIndex = indexToRemove[d] - d;
        if(removeEleIndex > textBoxesArr.current.length){return false;}
        textBoxesArr.current.splice(removeEleIndex,1);
      }
      setselectedTextBoxIndex('');
    }
  }

  const updateTextBackground = (i) => {
    setSelectedBackgroundColorOptionIndex(i);
    
    let selectedTBValue = '';
    let selectedTSValue = '';
    backgroundcolorOptions.forEach((tb,j) => {
      if(i === j){
        selectedTBValue = tb.value;
        selectedTSValue = tb.textEffect;
      }
    });
    if(selectedTBValue !== ''){
        textBoxesArr.current[selectedTextBoxIndex].childStyles.backgroundColor = selectedTBValue;
        textBoxesArr.current[selectedTextBoxIndex].childStyles.textShadow = selectedTSValue;
    }
  }

  const textareaFocus = (e) => {
    setTextareaFocusState(true);
    setTimeout(function(){
      textareaRef.current.focus(); 
      setTextareaFocusState(true);
    },300);
  }

  const doneEditing = (e) => {
    e.preventDefault();
    console.log("Done Editing...");
    setTextareaFocusState(false);
    setSelectedOptionIndex('');
    textareaRef.current.blur(); 
  }

  const textareaBlur = () => {
    setTextareaFocusState(false);
  }

  if(selectedOptionIndex === 0){
    updateTextareaHeightonFontChange();
  }

  // let doneBtnPos = '30px';
  // if(document.getElementById("captionContID"+selectedTextBoxIndex) && selectedOptionIndex === 0){
  //   let elePosY = parseInt(document.getElementById("captionContID"+selectedTextBoxIndex).getBoundingClientRect().y);
  //   if(elePosY < parseInt(window.innerHeight/1.7)){
  //     doneBtnPos = (document.getElementById('fontOptionsList').getBoundingClientRect().y - 50) + 'px';
  //   }else{
  //     doneBtnPos = (document.getElementById('fontOptionsList').getBoundingClientRect().y + document.getElementById('fontOptionsList').offsetHeight + 25) + 'px';
  //   }
  // }
  
  return (
    <>
    {usertoken ? 
      <div className="App-page" onClick={(e) => handleBodyClick(e)}>
        
            <div className="fixedDiv">
              {
                (mode === 'edit' && custom === '1') ?
                  <span className="backLink linkZIndex"><Link to={"/user-media?bid="+editableBid+"&t="+mediaData.type}></Link></span>
                : (mode === 'edit' && custom === '0') ?
                  <span className=""></span>
                :
                  <span className="backLink linkZIndex"><Link to="/digital-sign"></Link></span>
              }
              {
                (textareaFocusState || selectedOptionIndex === 0) && selectedTextBoxIndex !=='' && pageState == 1 &&
                  <span className="doneLink linkZIndex" onClick={(e) => doneEditing(e)} onTouchEnd={(e) => doneEditing(e)}>DONE</span>
              }
            </div>
        <div className="mediaCont" id="mediaContDiv">
          {
          mediaData.url ?
            ((mediaData.mime).indexOf('video') > -1) ?
            <video src={mediaData.url} width="100%" height="100%" style={{ maxHeight:'100vh' }} playsInline autoPlay muted loop></video>
            : ((mediaData.mime).indexOf('image') > -1) ?
            <div className="bannerImg" style={{ backgroundImage:'url('+mediaData.url+')' }}></div>
            :
            <div></div>
           :
           <div></div>
          }
        </div>
        
        {
           (textareaFocusState || selectedOptionIndex === 0) &&  pageState == 1 &&
           <div className="filterOverlay"></div>
        }
        
        { textBoxesArr.current.map((option,index) => {
            return (
              <Draggable key={option.keyCount} defaultPosition={dragXYRef.current?dragXYRef.current[index]:{x:0,y:0}} disabled={((index === selectedTextBoxIndex && selectedOptionIndex === 0 && textareaFocusState) || pageState == 2)?true:false} axis="both" bounds={{top:-dragHeightBounds, bottom:dragHeightBounds + 50, left:-dragWidthBounds, right:dragWidthBounds}} onDrag={(e) => dragEvent(e)} onStart={() => dragStartEvent(index)} onStop={(e)=>handleStop(e,index)}>
                <p id={"captionContID"+index} className={`captionCont ${((selectedVAOptionIndex === 3 || mode === 'edit')?'unsetPos':'unsetTransform')}`} style={option.parentStyles}>
                  <span className="captionText" id={"captionText"+index} onTouchEnd={(e) => captionClicks(index)} style={{display: ((selectedOptionIndex !== 0 || pageState === 2 || index !== selectedTextBoxIndex) && option.text !== '')?'':'none', backgroundColor:option.childStyles.backgroundColor, textShadow: option.childStyles.textShadow}} dangerouslySetInnerHTML={createHTMLContent(index)}></span>
                </p>
              </Draggable>
            )
          })
        }
        { textBoxesArr.current.map((option,index) => {
            return (
              <Draggable key={"f"+index} disabled={true} axis="none">
              <p key={"F"+index} id={"captionContIDFixed"+index} className="captionContFixed" style={option.parentStyles}>
                { pageState === 1 &&
                  <textarea placeholder="Type your text here" ref={(index === selectedTextBoxIndex)?textareaRef:null} id={"textInput"+index} rows="1" onClick={(e) => textareaClicks(e,index)} onTouchEnd={(e) => textareaClicks(e,index)} style={{display: (selectedOptionIndex === 0 && index === selectedTextBoxIndex)?'block':'none', height:option.textareaHeight, maxHeight:windowHTextareaView+'px', marginTop:'-'+(option.text !== ''?(((option.textareaHeight.replace('px','')/2) < windowHTextareaView/2?option.textareaHeight.replace('px','')/2:windowHTextareaView/2)+'px'):'0px'), backgroundColor:option.childStyles.backgroundColor, textShadow: option.childStyles.textShadow}} onFocus={(e) => textareaFocus(e)} onBlur={(e) => textareaBlur()} onChange={(e) => updateText(e)} value={option.text}></textarea>
                }
              </p>
              </Draggable>
            )
          })
        }

        {
          (dragging) &&
          <button ref={trashRef} className={`trash ${(isIntersectTrash) ? 'zoomtrash' : ''}`}></button>
        }

        {/* {
          (textareaFocusState || selectedOptionIndex === 0) &&
          <button className='doneBtn' style={{top:doneBtnPos}} onClick={(e) => doneEditing(e)} onTouchEnd={(e) => doneEditing(e)}>DONE</button>
        } */}

        {/* Filter options on left side */}
        { pageState === 1 &&
        <div className="filterPanel">
          { filterOptions.map((option,index) => {
              return (
                <button key={index} className={`filterOption ${option.class} ${index === selectedOptionIndex ? 'active':''}`} onClick={() => (index === selectedOptionIndex) ? setSelectedOptionIndex('') : selectFilter(index)}></button>
              )
            })
          }
        </div>
        }
        {/* Font Filter options on right side */}
        { pageState === 1 &&
        <div className="filterOptionsPanel" id="fontOptionsList" style={{display: (selectedOptionIndex === 0)?'block':'none'}}>
          { fontOptions.map((option,index) => {
              return (
                <button key={index} className={`fontOption ${option.class} ${index === selectedFontOptionIndex ? 'active':''}`} onClick={() => updateFont(index)}></button>
              )
            })
          }
        </div>
        }
        {/* Vertical Alignment Filter options on right side */}
        { pageState === 1 &&
        <div className="filterOptionsPanel" style={{display: (selectedOptionIndex === 4)?'block':'none'}}>
          { vaOptions.map((option,index) => {
              return (
                <button key={index} className={`vaOption ${option.class} ${index === selectedVAOptionIndex ? 'active':''}`} onClick={() => updateVAlign(index)}></button>
              )
            })
          }
        </div>
        }
        {/* Text Alignment Filter options on right side */}
        { pageState === 1 &&
        <div className="filterOptionsPanel" style={{display: (selectedOptionIndex === 5)?'block':'none'}}>
          { taOptions.map((option,index) => {
              return (
                <button key={index} className={`taOption ${option.class} ${index === selectedTAOptionIndex ? 'active':''}`} onClick={() => updateTAlign(index)}></button>
              )
            })
          }
        </div>
        }
        {/* Text Color Filter options on right side */}
        { pageState === 1 &&
        <div className="filterOptionsPanel" style={{display: (selectedOptionIndex === 2)?'block':'none'}}>
          { colorOptions.map((option,index) => {
              return (
                <button key={index} className={`colorOption ${option.class} ${index === selectedColorOptionIndex ? 'active':''}`} onClick={() => updateTextColor(index,'')}></button>
              )
            })
          }
          <button className={`colorOption colorcustom ${6 === selectedColorOptionIndex ? 'active':''}`} onClick={() => showCustomColorView(6)}></button>
          { (6 === selectedColorOptionIndex && customColorView) &&
            <HexColorPicker id="colorpicker" color={customcolor} onChange={(e) => updateTextColor(6,e)} />
          }
        </div>
        }
        {/* Background text Color Filter options on right side */}
        { pageState === 1 &&
        <div className="filterOptionsPanel" style={{display: (selectedOptionIndex === 3)?'block':'none'}}>
          { backgroundcolorOptions.map((option,index) => {
              return (
                <button key={index} className={`bgcolorOption ${option.class} ${index === selectedBackgroundColorOptionIndex ? 'active':''}`} onClick={() => updateTextBackground(index)}></button>
              )
            })
          }
        </div>
        }

        { pageState === 1 &&
        <div className="filterOptionsPanel" style={{display: (selectedOptionIndex === 1)?'block':'none'}}>
          <div className='fontSlider'>
            <input type="range" className="slider" name="fontSize" value={fontsize} min="3" step="1" onChange={(e) => updateFontSize(e)} max="15" orient="vertical" />
          </div>
        </div>
        }
        {
          pageState === 1 &&
          <button id="userCustomizeNextBtn" className="nextBtn" onClick={() => nextBtnClick()}>&nbsp;</button>
        }
        { pageState === 2 &&
        <div className="bottomBtns">
          <button id="userCustomizeEditBtn" className="App-button" onClick={() => handleBottomBtnsClick('edit')}>Edit </button>
          <button id="userCustomizeConfirmBtn" className="App-button" onClick={() => handleBottomBtnsClick('confirm')}>Confirm </button>
        </div>
        }
      </div>
      :
      <Signin/>
    }
    </>
  );
}