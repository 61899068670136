// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.previewCont{
  width: 100%;
  height: 54vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}

.bottomStrip{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255,255,255,0.15);
  padding: 10px 0;
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
}

.bottomStrip label{
  font-size: 18px;
 width:60%;
 color: #ffffff;
 text-align: left;
 margin-left: 10px;
 margin-bottom: 0px;
 float: left;
 line-height: 28px;
}

.bottomStrip span{
  font-size: 24px;
  font-weight: 600;
  float: right;
  margin-right: 10px;
 }`, "",{"version":3,"sources":["webpack://./src/App/pages/previewselection/PreviewSelection.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,4BAA4B;EAC5B,kCAAkC;EAClC,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,WAAW;EACX,wCAAwC;EACxC,eAAe;EACf,kCAA0B;UAA1B,0BAA0B;AAC5B;;AAEA;EACE,eAAe;CAChB,SAAS;CACT,cAAc;CACd,gBAAgB;CAChB,iBAAiB;CACjB,kBAAkB;CAClB,WAAW;CACX,iBAAiB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;CACnB","sourcesContent":[".previewCont{\n  width: 100%;\n  height: 54vh;\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: center center;\n  position: relative;\n}\n\n.bottomStrip{\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  background-color: rgba(255,255,255,0.15);\n  padding: 10px 0;\n  backdrop-filter: blur(3px);\n}\n\n.bottomStrip label{\n  font-size: 18px;\n width:60%;\n color: #ffffff;\n text-align: left;\n margin-left: 10px;\n margin-bottom: 0px;\n float: left;\n line-height: 28px;\n}\n\n.bottomStrip span{\n  font-size: 24px;\n  font-weight: 600;\n  float: right;\n  margin-right: 10px;\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
