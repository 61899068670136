 import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../images/logo.png';
import './LoginOtp.css';
import 'react-phone-number-input/style.css';
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input';

export const LoginOtp = () => {
  console.log("In Admin Login OTP page");
  const navigate = useNavigate();
  const [value, setValue] = useState();
  const [email, setEmail] = useState('');
  const [errormsg, setErrorMessage] = useState('');
  const [code, setCode] = useState('');
  const [errormsgcode, setCodeErrorMessage] = useState('');
  const [pagestep, setPageStep] = useState(1);
  const [loginType, setLoginType] = useState('m');
  const [apiprocessingFlag, setAPIProcessingFlag] = useState(false);

  const loginSubmit = (e) => {
    e.preventDefault();
    setErrorMessage("");
    if(loginType === 'e'){
      if(!email){
        setErrorMessage("E-Mail is required.");
        return;
      }else if (!isValidEmail(email)){
        setErrorMessage("E-Mail is invalid.");
        return;
      }
    }else{
      if(!value){
        setErrorMessage("Mobile Number is required.");
        return;
      }else if(!(isValidPhoneNumber(value))){
        setErrorMessage("Mobile Number is invalid.");
        return;
      }
    }
    if(apiprocessingFlag){ return; }
    setAPIProcessingFlag(true);
    let loginApi = '';
    let apiData = {};
    if(loginType === 'm'){ // mobile signin
      loginApi = process.env.REACT_APP_API_ENDPOINT+"clubuser-mobile-login";
      apiData = {mobile_number: value};
    }else{ // email signin
      loginApi = process.env.REACT_APP_API_ENDPOINT+"clubuser-email-login";
      apiData = {email: email};
    }
    axios.post(loginApi, apiData)
    .then(res => {
      if(res.status === 200){
        setPageStep(2);
      }
      setAPIProcessingFlag(false);
    })
    .catch((error) => {
      if(error.response.data.msg){
        setErrorMessage(error.response.data.msg);
      }
      setAPIProcessingFlag(false);
    });
  }

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const verifyCodeSubmit = (e) => {
    e.preventDefault();
    setCodeErrorMessage("");
    if(!code){
      setCodeErrorMessage("Code is required.");
      return;
    }

    let verifyCodeApi = '';
    let verifyDataApi = {};
    if(loginType === 'm'){ // mobile signin
      verifyCodeApi = process.env.REACT_APP_API_ENDPOINT+"clubuser-verify-mobile-otp";
      verifyDataApi = { mobile_number: value, otp: code };
    }else{ // email signin
      verifyCodeApi = process.env.REACT_APP_API_ENDPOINT+"clubuser-verify-email-otp";
      verifyDataApi = { email: email, otp: code };
    }
    axios.post(verifyCodeApi, verifyDataApi)
    .then(res => {
      if(res.status === 200){
        localStorage.setItem('usertoken',res.data.token);
        localStorage.setItem('userrole',res.data.role);
        navigate({pathname: '/dashboard'});
      }
    })
    .catch((error) => {
      if(error.response.data.msg){
        setCodeErrorMessage(error.response.data.msg);
      }
    });
  }

  const backClicked = (e) => {
    setPageStep(1);
    setCodeErrorMessage("");
  }

  // const changeSignup = (e) => {
  //   setErrorMessage("");
  //   if(loginType === 'm'){
  //     setLoginType('e');
  //   }else{
  //     setLoginType('m');
  //   }
  // }

  return (
    <>
    { pagestep === 1 ?
      <div className="Admin-page otp-screen">
          <img src={logo} className="Admin-logo" alt="logo" /><br/>
          <div className="outerCont">
            <form onSubmit={loginSubmit}>
              <label>Authorized {loginType === 'm' ? 'Mobile Number':'E-Mail'}</label>
              {loginType === 'm' ? 
                <PhoneInput placeholder="Mobile Number" defaultCountry="US" value={value} onChange={setValue}/>
              :
                <div><input type="text" value={email} autoComplete="off" onChange={(e) => setEmail(e.target.value)} maxLength="50" placeholder="example@email.com" /></div>
              }
              <br/><br/>
              {errormsg && 
                <div className='error'>{errormsg}</div>
              }
              <button type="submit" className="Admin-button">Next</button><br/>
            </form>
          </div>
          {/* <button className="Admin-text-link" onClick={(e) => changeSignup()}>Sign in with {loginType === 'm' ? 'email':'mobile number'} instead</button><br/> */}
      </div>
      :
      <div className="Admin-page otp-screen">
          <img src={logo} className="Admin-logo" alt="logo" /><br/><br/><br/>
          <span className="backLink" onClick={(e) => backClicked()}></span>
          <div className="outerCont">
            <form style={{marginTop: "-30px"}} onSubmit={verifyCodeSubmit}>
              <label>Verify your {loginType === 'e' ? 'e-mail':'mobile number'} <br/>with the code we just <br/> {loginType === 'e' ? 'sent':'messaged'} you</label>
              <input type="text" value={code} autoComplete="off" onChange={(e) => setCode(e.target.value)} maxLength="10" placeholder="code" /><br/><br/>
              {errormsgcode && 
                <div className='error'>{errormsgcode}</div>
              }
              <button type="submit" id="clientAppLoginBtn" className="Admin-button">Next</button><br/>
            </form>
          </div>
      </div>
    }
    </>
  );
}