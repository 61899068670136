import axios from 'axios';
import { useState, useEffect } from 'react';
import {Link, useSearchParams, createSearchParams, useNavigate} from 'react-router-dom';
import logo from '../../images/logo.png';
import '../previewselection/PreviewSelection.css'
import { Signin } from '../signin/Signin';

export const PreviewSelection = () => {
  console.log("In Preview Selection page");

  const navigate = useNavigate();
  const usertoken = localStorage.getItem('appusertoken');
  const [queryParams] = useSearchParams();
  const dso = queryParams.get('dso');
  const [dsoData, setDSOData] = useState([]);
  const [freeOrder,setFreeOrder] = useState('');

  useEffect(() => {
    const tokenAPI = localStorage.getItem('appusertoken');
    const headers = {
      'Content-Type': 'application/json',
      'Token': tokenAPI
    }
    let tbID = localStorage.getItem('appusertblid');
    let clID = localStorage.getItem('appcid');
    let firstOrderApi = process.env.REACT_APP_API_ENDPOINT+"client-app/is-first-order/"+tbID+"/"+clID;
    axios.get(firstOrderApi, {
      headers: headers
    })
    .then(res => {
      if(res.status === 200){
        if(res.data.first_order && res.data.first_order === true){
          setFreeOrder('FREE');
        }else{
          setFreeOrder('$');
        }
      }
    })
    .catch((error) => {
      if(error.response.status === 401){
        localStorage.clear();
        navigate({ pathname: '/' });
      }
    });

    let dsoPreviewApi = process.env.REACT_APP_API_ENDPOINT+"client-app/get-single-digital-object-club/"+dso;
    axios.get(dsoPreviewApi, {
      headers: headers
    })
    .then(res => {
      if(res.status === 200){
        if(res.data){
          setDSOData(res.data.digital_sign);
        }
      }
    })
    .catch((error) => {
      if(error.response.status === 401){
        localStorage.clear();
        navigate({ pathname: '/' });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const customizeBtnClicked = (e) => {
      navigate({
        pathname: '/customize',
        search: createSearchParams({
          mo: dso,
          c:0
        }).toString()
      });
  }

  return (
    <>
    {usertoken ? 
      <div className="App-page">
          <section className="topSection">
            <img src={logo} className="App-logo-medium" alt="logo" /><br/>
            { dsoData.of_type_id ?
              <span className="backLink"><Link to={`/digital-sign-list?dsc=${dsoData.of_type_id}`}></Link></span>
            :
              <span className="backLink"><Link to="/digital-sign"></Link></span>
            }
          </section>
          { dsoData.id ?
            <section className="bottomSection"><br/>
              <div className="previewCont" style={{ backgroundImage:'url('+((dsoData.mime.indexOf('image') > -1)?dsoData.url:'')+')' }}>
              { ((dsoData.mime).indexOf('video') > -1) &&
               <video src={dsoData.url} width="100%" height="100%" playsInline autoPlay muted></video> 
              }
                <div className="bottomStrip">
                  <label>{dsoData.title}</label>
                  <span>{freeOrder !== '$'?freeOrder:dsoData.currency + dsoData.price}</span>
                  </div>
              </div><br/>
              <button id="userCustomizeBtn" className="App-button" onClick={customizeBtnClicked}>Customize</button>
            </section>
            :
            <section className="bottomSection"></section>
          }
      </div>
      :
      <Signin/>
    }
    </>
  );
}