import { useState } from 'react';

export const DashboardListItem = (props) => {

  const data = props.data;
  const [openPopup, setOpenPopup] = useState();
  const userRole = localStorage.getItem('userrole');
  
  return (
    <>
        {/* <dt>{props.name}<span className={props.icon}></span></dt> */}
        <dt></dt>
        {
          data.length ?
          data.map((listItem) =>
            <dd key={listItem.ID}>
              <div>
              <label>Table {listItem.Table.TableID}</label>
              <small>{listItem.Table.UserName} | {
              new Intl.DateTimeFormat('default', {day: '2-digit',month: 'short', hour: '2-digit', minute: '2-digit'}).format(new Date(listItem.Table.DateOfBooking))
              }</small>
              <small>Amount: ${listItem.Amount}</small> 
              </div>
              <div><button className="Admin-button clientAppPreviewBtn" onClick={() => props.click(listItem.ID)}>{props.btnname}</button></div>
              {
               listItem.Status === 3 && userRole != 2 &&
               <button className="Admin-text-link mt10 clientAppRedoBtn" onClick={() => setOpenPopup(listItem.ID)}>Redo</button> 
              }
              {
                openPopup === listItem.ID &&
                <div className="popupOverlay">
                  <div className="popupContainer">
                    <span className='close' onClick={() => setOpenPopup()}></span>
                    <div>Are you sure you would like to put this back to the In-Progress queue so that you can play it again? <br/><br/>
                    <div className='mt10'><button className="Admin-button-small" onClick={() => props.redo(listItem.ID,2)}>Yes</button>&nbsp;&nbsp;&nbsp;<button className="Admin-button-small" onClick={() => setOpenPopup()}>No</button></div>
                    </div>
                    <div className='mt10'></div>
                  </div>
                </div>
              }
            </dd>
          ) 
          :
          <dd>
            <p className='textCenter'><small>You don't have any {props.name === 'Completed'?'completed':'requested'} orders yet.</small></p>
          </dd>
        }
    </>
  );
}