import { useState } from 'react';

export const DashboardRejectedListItem = (props) => {

  const data = props.data;
  const userRole = localStorage.getItem('userrole');
  const [openPopup, setOpenPopup] = useState();

  return (
    <>
        {/* <dt>{props.name}<span className={props.icon}></span></dt> */}
        <dt></dt>
        {
          data.length ?
          data.map((listItem) =>
            <dd key={listItem.ID}>
              <div>
              <label>Table {listItem.Table.TableID}</label>
              <small>{listItem.Table.UserName} | {
              new Intl.DateTimeFormat('default', {day: '2-digit',month: 'short', hour: '2-digit', minute: '2-digit'}).format(new Date(listItem.Table.DateOfBooking))
              }</small>
              <small>Amount: ${listItem.Amount}</small> 
              </div>
              {
                userRole != 2 ?
                <>
                  <div className="multipleBtns">
                    <button className="Admin-button clientAppViewBtn" onClick={() => props.click(listItem.ID)}>View</button>
                    <button className="Admin-button clientAppUndoBtn" onClick={() => props.update(listItem.ID,1)}>Undo</button>
                  </div>
                  {listItem.Status === 6 ?
                    <button className="Admin-text-link refunded">Refunded</button>
                  : listItem.Amount > 0 &&
                    <button className="Admin-text-link clientAppRefundLink" onClick={() => setOpenPopup(listItem.ID)}>Refund</button>
                  }
                </>
                :
                <div>
                  <button className="Admin-button clientAppViewBtn" onClick={() => props.click(listItem.ID)}>View</button>
                </div>
              }
              {
                openPopup === listItem.ID && listItem.Status === 4 &&
                <div className="popupOverlay">
                  <div className="popupContainer">
                    <span className='close' onClick={() => setOpenPopup()}></span>
                    <div>Are you sure you would like to refund this order? <br/><br/>
                    <div className='mt10'><button className="Admin-button-small" onClick={() => props.refund(listItem.ID)}>Yes</button>&nbsp;&nbsp;&nbsp;<button className="Admin-button-small" onClick={() => setOpenPopup()}>No</button></div>
                    </div>
                    <div className='mt10'></div>
                  </div>
                </div>
              }
            </dd>
          ) 
          :
          <dd>
            <p className='textCenter'><small>You don't have any rejected orders yet.</small></p>
          </dd>
        }
    </>
  );
}