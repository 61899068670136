
export const SignTypeItem = (props) => {
  return (
    <>
      {
        props.class === "signBox" ? 
        <div className={props.class} onClick={() => props.click(props.index)}>
          <div className={`thumbImage ${props.customclass}`} style={{ backgroundImage:'url('+props.image+')' }}>
            { props.cost != "" &&
              <div className="stripBottom"><span>{props.cost}</span></div>
            }
          </div>
          <div><span>{props.label}</span></div> 
        </div>
        :
        <div className={props.class} onClick={() => props.click(props.index)}>
          <div className={`thumbImage ${props.customclass}`} style={{ backgroundImage:'url('+props.image+')' }}></div>
          <div><span>{props.cost}</span></div>
        </div>
      }
    </>
  );
}