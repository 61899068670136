// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.marginNTop{
    margin-top: -150px;
}

.mediaContPortrait{
    width: 100vh !important;
    height: 100vw !important;
  }

  .rotateP{
    transform: rotate(270deg) translateY(-56.25%);
    transform-origin:top right;
    /* transform: rotate(90deg) translateY(-100%);
    transform-origin:top left; */
    width: 100vh !important;
    height: 100vw !important;
    min-height: 100vw !important;
  }

  .bannerImgP{
    background-size: cover;
    height: 100vw;
    width: 100vh;
    background-repeat: no-repeat;
    background-position: center center;
  }`, "",{"version":3,"sources":["webpack://./src/Admin/pages/screenhlsandroid/Screenhlsandroid.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,uBAAuB;IACvB,wBAAwB;EAC1B;;EAEA;IACE,6CAA6C;IAC7C,0BAA0B;IAC1B;gCAC4B;IAC5B,uBAAuB;IACvB,wBAAwB;IACxB,4BAA4B;EAC9B;;EAEA;IACE,sBAAsB;IACtB,aAAa;IACb,YAAY;IACZ,4BAA4B;IAC5B,kCAAkC;EACpC","sourcesContent":[".marginNTop{\n    margin-top: -150px;\n}\n\n.mediaContPortrait{\n    width: 100vh !important;\n    height: 100vw !important;\n  }\n\n  .rotateP{\n    transform: rotate(270deg) translateY(-56.25%);\n    transform-origin:top right;\n    /* transform: rotate(90deg) translateY(-100%);\n    transform-origin:top left; */\n    width: 100vh !important;\n    height: 100vw !important;\n    min-height: 100vw !important;\n  }\n\n  .bannerImgP{\n    background-size: cover;\n    height: 100vw;\n    width: 100vh;\n    background-repeat: no-repeat;\n    background-position: center center;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
