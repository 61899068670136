import axios from 'axios';
import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { PaymentPopup } from "../pages/paymentpopup/PaymentPopup";
import {Link, useSearchParams, useNavigate} from 'react-router-dom';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PKEY);

export default function StripePayment() {
  const [clientSecret, setClientSecret] = useState("");
  const [queryParams] = useSearchParams();
  const navigate = useNavigate();
  const bid = queryParams.get('b_id');
  const mo = queryParams.get('mo');
  const custom = queryParams.get('c');

  useEffect(() => {

    if(bid){
      const tokenAPI = localStorage.getItem('appusertoken');
      let checkBookingApi = process.env.REACT_APP_API_ENDPOINT+"client-app/get-booking-detail/"+bid+"?v="+Math.random();
      const headers = {
        'Content-Type': 'application/json',
        'Token': tokenAPI
      }
      axios.get(checkBookingApi, {
        headers: headers
      })
      .then(res => {
        if(res.status === 200){
          if(res.data.request_detail){
            if(res.data.request_detail.RequestStatus >= 1){
              navigate({ pathname: '/digital-sign'});
            }else{
              // Create PaymentIntent as soon as the page loads
              const cpiApiUrl = process.env.REACT_APP_API_ENDPOINT+"client-app/create-payment-intent";
              fetch(cpiApiUrl, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ id: bid }),
              })
                .then((res) => res.json())
                .then((data) => setClientSecret(data.clientSecret));
            }
          }
        }
      })
      .catch((error) => {
        if(error.response.status === 401){
          localStorage.clear();
          navigate({ pathname: '/' });
        }
      });
    }
    
  }, [bid, navigate]);

  const appearance = {
    theme: 'night',
    variables: {
      colorPrimary: '#DEB215'
    }
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="App-page">
      <span className="backLink"><Link to={`/review-order?b_id=${bid}&mo=${mo}&c=${custom}`}></Link></span>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <PaymentPopup bid={bid} />
        </Elements>
      )}
    </div>
  );
}