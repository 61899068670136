import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import {useSearchParams } from 'react-router-dom';
import './Screen.css';
import logo from '../../images/logo.png';

export const Screen = () => {
 console.log("In Screen page");

 const [queryParams] = useSearchParams();
 const screenId = queryParams.get('id');
 const [screenData, setscreenData] = useState({});
 const [passCode, setPassCode] = useState('');
 const [passCodeVerified, setPassCodeVerified] = useState(0);
 const [errormsg, setErrorMessage] = useState('');
 const [updateTop, setUpdateTop] = useState(0);
 const currentPlayingOrder = useRef('');
 let apiInterval = useRef(null);

 const passCodeSubmit = (e) => {
    e.preventDefault();
    setErrorMessage("");
    if(!passCode){
      setErrorMessage("Passcode is required.");
      return;
    }
  }

  useEffect(() => {
    if(screenId){
      setPassCodeVerified(1);
      checkForUpdates(1);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function checkForUpdates(pass){
    if(!pass){return;}
    clearInterval(apiInterval.current);
    apiInterval.current = setInterval(() => {
      fetchApiUpdates();
    }, 3000);
  }

  function fetchApiUpdates(){
    let screenApi = process.env.REACT_APP_API_ENDPOINT+"screen/screen-details/" +screenId;
    const headers = {
      'Content-Type': 'application/json'
    }
    axios.get(screenApi, {
      headers: headers
    })
    .then(res => {
      if(res.status === 200){
        if(res.data.data){
          if(res.data.data.currently_playing){
            if(Object.keys(res.data.data.currently_playing).length){
              if(res.data.data.currently_playing.order_id !== currentPlayingOrder.current){
                setUpdateTop(0);
                setscreenData(res.data.data.currently_playing);
                currentPlayingOrder.current = res.data.data.currently_playing.order_id;
              }
            }else{
              setscreenData({});
            }
          }else{
            setscreenData({});
            currentPlayingOrder.current = '';
          }
        }
      }else{
        setscreenData({});
        currentPlayingOrder.current = '';
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  function createHTMLContent(text){
    return {__html: text}
  }

  const mediaLoaded = () => {  // media = video
    setUpdateTop(1);
  };

  let customizedText = '';
  let mediaContentObj = {};
  let multiTextBoxesMediaContentArr = [];
  if(Object.keys(screenData).length){
    const mediaContent = screenData.styles;
    mediaContentObj = JSON.parse(mediaContent);
    if(mediaContentObj.data){
      if(mediaContentObj.data.length){ // has multiple textboxes
        multiTextBoxesMediaContentArr = JSON.parse(mediaContent).data;
        mediaContentObj = {};
        if(updateTop){
          for(let l=0; l<multiTextBoxesMediaContentArr.length; l++){
            if(multiTextBoxesMediaContentArr[l].parentStyles.top && multiTextBoxesMediaContentArr[l].relativeTop){ // Adjust top due to blank black spaces at top/bottom of some devices
              let relativeTop = parseInt(multiTextBoxesMediaContentArr[l].relativeTop.replace('px',''));
              if(document.getElementById('mediaContDiv')){
                let previewMediaFrame = document.getElementById('mediaContDiv').getBoundingClientRect();
                if(previewMediaFrame.height){
                  let updatedTop = parseInt((relativeTop/multiTextBoxesMediaContentArr[l].mediaObjDimensions.height*previewMediaFrame.height) + previewMediaFrame.top);
                  multiTextBoxesMediaContentArr[l].parentStyles.top = updatedTop+'px';
                }
              }
            }
          }
        }
      }else{
        mediaContentObj = {};
      }
    }
    if(Object.keys(mediaContentObj).length){
      customizedText = mediaContentObj.text;
    }
    if(screenData.request_type !== 102 && screenData.url && !updateTop){ // media = photo
      setUpdateTop(1);
    }
  }
  
  return (
    <>
      { passCodeVerified ?
        Object.keys(screenData).length ?
        <div className="Admin-page">
          <div className="mediaCont" id="mediaContDiv" style={{ maxHeight:+window.innerHeight+'px' }}>
          {
            screenData.request_type === 102 ? <video src={screenData.url} width="100%" height="100%" style={{ maxHeight:+window.innerHeight+'px' }} playsInline autoPlay muted loop onLoadedData={() => {mediaLoaded();}}></video> : <div className="bannerImg" style={{backgroundImage: "url(" + screenData.url + ")"}}></div>
          }
          </div>
          {
            (customizedText !== '' && Object.keys(mediaContentObj).length) ?
            <p className="captionCont" style={mediaContentObj.parentStyles}>
              <span className="captionText" style={{backgroundColor:mediaContentObj.childStyles.backgroundColor, textShadow:((mediaContentObj.childStyles.textShadow)?mediaContentObj.childStyles.textShadow:"")}} dangerouslySetInnerHTML={createHTMLContent(customizedText)}></span>
            </p>
            : 
            multiTextBoxesMediaContentArr.map((captiondata,index) =>
              <p key={index} className={`captionCont ${(updateTop && captiondata.text != "")?'':'hideCaptions'}`} style={captiondata.parentStyles}>
                <span className="captionText" style={{backgroundColor:captiondata.childStyles.backgroundColor, textShadow:((captiondata.childStyles.textShadow)?captiondata.childStyles.textShadow:"")}} dangerouslySetInnerHTML={createHTMLContent(captiondata.text)}></span>
              </p>
            )
          }
        </div>
        :
        <div className="Admin-page">
          <img src={logo} alt="logo" className="marginNTop" />
        </div>
        :
        <div className="Admin-page">
          <form id="passcodeForm" autoComplete="off" onSubmit={passCodeSubmit}>
            <label>Enter Passcode</label><br/>
            <input type="password" value={passCode} autoComplete="off" onChange={(e) => setPassCode(e.target.value)} placeholder="" /><br/><br/>
            {errormsg && 
              <div className='error'>{errormsg}</div>
            }
            <br/>
            <button type="submit" className="Admin-button">Submit</button><br/>
          </form>
        </div>
      }
    </>
  );
}