import axios from 'axios';
import { useState } from 'react';
import {useNavigate} from 'react-router-dom';
import logo from '../../images/logo.png';
import './Login.css';

export const Login = () => {
  console.log("In Login page");
  const navigate = useNavigate();
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [errormsg, setErrorMessage] = useState('');
  const loginSubmit = (e) => {
    e.preventDefault();
    setErrorMessage("");
    if(!username){
      setErrorMessage("Username is required.");
      return;
    }
    if(!password){
      setErrorMessage("Password is required.");
      return;
    }

    let loginApi = process.env.REACT_APP_API_ENDPOINT+"login";
    axios.post(loginApi, { username: username, password: password })
    .then(res => {
      if(res.status === 200){
        //console.log(res.data);
        localStorage.setItem('usertoken',res.data.token);
        localStorage.setItem('userrole',res.data.role);
        smartlook('identify', res.data.user_id, {"name": res.data.username});
        navigate({
          pathname: '/dashboard'});
      }
    })
    .catch((error) => {
      if(error.response.data.msg){
        setErrorMessage(error.response.data.msg);
      }
    });
  }

  return (
    <>
      <div className="Admin-page">
          <img src={logo} className="Admin-logo" alt="logo" />
          <form onSubmit={loginSubmit}>
            <label>Username</label><br/>
            <input type="text" value={username} autoComplete="off" onChange={(e) => setUserName(e.target.value)} placeholder="" /><br/><br/>
            <label>Password</label><br/>
            <input type="password" value={password} autoComplete="off" onChange={(e) => setPassword(e.target.value)} placeholder="" /><br/><br/>
            {errormsg && 
              <div className='error'>{errormsg}</div>
            }
            <button type="submit" id="clientAppLoginBtn" className="Admin-button">Next</button><br/>
          </form>
          <button id="clientAppForgetLink" className="Admin-text-link">Forgot Username or Password?</button><br/>
      </div>
    </>
  );
}