// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.signBoxCont{
  width: 100%;
  overflow-y: auto;
  height: 45vh;
}

.signBox{
  width: 44%;
  float: left;
  height: 21vh;
  margin: 1% 3%;
}

.signBox div{
  font-size: 13px;
  padding: 5px 0px;
  font-weight: 500;
}

.signBox div span{
  display: inline-block;
  vertical-align: top;
  text-align: center;
  width: 100%;
}

.signBox div.stripBottom span{
  width: 100%;
  background-color: rgba(255,255,255,0.35);
  color: #fff;
  position: absolute;
  bottom: -2px;
  left: 0;
  padding: 5px 0;
}

.signBox .active{
  border-color: #DEB215 !important;
}

.note{
  font-weight: 500;
  font-size: 13px;
}

.signBox .thumbImage{
  height: 14vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 2px solid transparent;
  position: relative;
  border: 1px solid #DEB215 !important;
}

.bg60{
  background-size: 90px !important;
  background-position: 50% calc(50%) !important;
}`, "",{"version":3,"sources":["webpack://./src/App/pages/digitalsign/DigitalSign.css"],"names":[],"mappings":";AACA;EACE,WAAW;EACX,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,UAAU;EACV,WAAW;EACX,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,mBAAmB;EACnB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,wCAAwC;EACxC,WAAW;EACX,kBAAkB;EAClB,YAAY;EACZ,OAAO;EACP,cAAc;AAChB;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,sBAAsB;EACtB,4BAA4B;EAC5B,kCAAkC;EAClC,6BAA6B;EAC7B,kBAAkB;EAClB,oCAAoC;AACtC;;AAEA;EACE,gCAAgC;EAChC,6CAA6C;AAC/C","sourcesContent":["\n.signBoxCont{\n  width: 100%;\n  overflow-y: auto;\n  height: 45vh;\n}\n\n.signBox{\n  width: 44%;\n  float: left;\n  height: 21vh;\n  margin: 1% 3%;\n}\n\n.signBox div{\n  font-size: 13px;\n  padding: 5px 0px;\n  font-weight: 500;\n}\n\n.signBox div span{\n  display: inline-block;\n  vertical-align: top;\n  text-align: center;\n  width: 100%;\n}\n\n.signBox div.stripBottom span{\n  width: 100%;\n  background-color: rgba(255,255,255,0.35);\n  color: #fff;\n  position: absolute;\n  bottom: -2px;\n  left: 0;\n  padding: 5px 0;\n}\n\n.signBox .active{\n  border-color: #DEB215 !important;\n}\n\n.note{\n  font-weight: 500;\n  font-size: 13px;\n}\n\n.signBox .thumbImage{\n  height: 14vh;\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: center center;\n  border: 2px solid transparent;\n  position: relative;\n  border: 1px solid #DEB215 !important;\n}\n\n.bg60{\n  background-size: 90px !important;\n  background-position: 50% calc(50%) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
