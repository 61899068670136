export const ReportListItem = (props) => {

  const data = props.data;
  
  return (
    <>
        {
          data.length ?
          data.map((listItem,i) =>
            
             <tr key={i}>
                <td>{(i<10?('0'+(i+1)).slice(-2):(i+1))}</td>
                <td>Table {listItem.table_number}<br/><small className="capitalize">{listItem.user_name}</small></td>
                <td>{new Intl.DateTimeFormat('default', {day: '2-digit',month: 'short', hour: '2-digit', minute: '2-digit'}).format(new Date(listItem.request_time))}<br/><small>{listItem.statusLabel}</small></td>
                <td>${listItem.payment} {(listItem.status !== 3)?<span className="smallCrossRounded"></span>:""}</td>
              </tr>
          ) 
          :
            <tr><td colSpan="4" >No data available</td></tr>
        }
    </>
  );
}