import axios from 'axios';
import { useState, useEffect } from 'react';
import {Link, useSearchParams, createSearchParams, useNavigate} from 'react-router-dom';
import { Signin } from '../signin/Signin';
import logo from '../../images/logo.png';
import '../revieworder/ReviewOrder.css';

export const ReviewOrder = () => {
  console.log("In Order details page");
  const usertoken = localStorage.getItem('appusertoken');
  const navigate = useNavigate();

  const [queryParams] = useSearchParams();
  const bid = queryParams.get('b_id');
  const mo = queryParams.get('mo');
  const custom = queryParams.get('c');

  const [promocode, setPromoCode] = useState('');
  const [errormsgcode, setErrorMessage] = useState('');
  const [promocodeApplied, setPromocodeApplied] = useState(0);
  const [amount, setAmount] = useState('');
  const [newAmount, setNewAmount] = useState('');
  const [disabledBtn, setDisabledBtn] = useState(0);

  const [tipPercent, setTipPercent] = useState('');
  const [customTip, setCustomTip] = useState('');
  const [tipId, setTipID] = useState(null);

  useEffect(() => {
    if(bid){
      const tokenAPI = localStorage.getItem('appusertoken');
      let checkBookingApi = process.env.REACT_APP_API_ENDPOINT+"client-app/get-booking-detail-meta/"+bid+"?v="+Math.random();
      const headers = {
        'Content-Type': 'application/json',
        'Token': tokenAPI
      }
      axios.get(checkBookingApi, {
        headers: headers
      })
      .then(res => {
        if(res.status === 200){
          if(res.data.request_detail){
            if(res.data.request_detail.RequestStatus >= 1){
              navigate({ pathname: '/digital-sign'});
            }else{
              setAmount(res.data.request_detail.RequestPayment);
              if(res.data.promo_log_detail){ // prefill promocode details
                if(res.data.promo_log_detail.promocode_id){
                  setAmount(res.data.promo_log_detail.original_amount);
                  setNewAmount(res.data.promo_log_detail.final_amount);
                  setPromocodeApplied(1);
                }
              }
              if(res.data.promo_detail){
                if(res.data.promo_detail.code){
                  setPromoCode(res.data.promo_detail.code);
                }
              }
              // prefill tips details
              if(res.data.server_tip){
                if(res.data.server_tip.id){
                  setTipID(res.data.server_tip.id);
                  setTipPercent(res.data.server_tip.tip_option);
                  if(res.data.server_tip.tip_option === 'custom'){
                    setCustomTip(res.data.server_tip.tip_amount);
                  }
                }
              }
            }
          }
        }
      })
      .catch((error) => {
        if(error.response.status === 401){
          localStorage.clear();
          navigate({ pathname: '/' });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const payNowButton = (e) => {
    let tipValueAPI = 0;
    if(tipPercent === 'custom'){
      if(customTip > 0){
        tipValueAPI = parseFloat(customTip);
      }
    }else if(tipPercent){
      tipValueAPI = parseFloat((tipPercent/100) * amount);
    }
    if(tipValueAPI > 0 || tipId){  // call API
      const tokenAPI = localStorage.getItem('appusertoken');
      let saveTipApi = process.env.REACT_APP_API_ENDPOINT+"client-app/server-tip";
      const headers = {
        'Content-Type': 'application/json',
        'Token': tokenAPI
      }
      const data = {
        club_id: localStorage.getItem('appcid'),
        booking_request_id: bid,
        tip_amount: tipValueAPI,
        tip_option:tipPercent,
        tip_id:tipId
      };
      setDisabledBtn(1);
      axios.post(saveTipApi, data, {
        headers: headers
      })
      .then(res => {
        setDisabledBtn(0);
        if(res.status === 200){
          navigate({
            pathname: '/payment',
            search: createSearchParams({
              b_id: bid,
              mo: mo,
              c: custom
            }).toString()
          });
        }
      })
      .catch((error) => {
        setDisabledBtn(0);
        if(error.response.data.msg){
          setErrorMessage(error.response.data.msg);
        }else if(error.response.status === 401){
          localStorage.clear();
          navigate({ pathname: '/' });
        }
      });
    }else{
      navigate({
        pathname: '/payment',
        search: createSearchParams({
          b_id: bid,
          mo: mo,
          c: custom
        }).toString()
      });
    }
  }

  const submitButton = async (e) => {
    e.preventDefault();
    setErrorMessage("");

    if(tipId){
      setNoTip();
    }

    let confirmApi = process.env.REACT_APP_API_ENDPOINT+"client-app/process-payment-new";
    const headers = {
      'Content-Type': 'application/json',
      'Token': usertoken
    }
    setDisabledBtn(1);
    axios.post(confirmApi, {id:"ZERO_AMOUNT", booking_id:bid}, {
      headers: headers
    })
    .then(res => {
      setDisabledBtn(0);
      if(res.status === 200){
        navigate({
          pathname: '/payment-success'});
      }
    })
    .catch((error) => {
      setDisabledBtn(0);
      if(error.response.data.msg){
        setErrorMessage(error.response.data.msg);
      }else if(error.response.status === 401){
        localStorage.clear();
        navigate({ pathname: '/' });
      }else if(error.response.status === 400){
        setErrorMessage("Sorry, please try again");
      }else if(error.response.status === 500){
        setErrorMessage("Sorry, please try again");
      }
    });
  }

  const setNoTip = (e) => {
    if(tipId){  // call API
      const tokenAPI = localStorage.getItem('appusertoken');
      let saveTipApi = process.env.REACT_APP_API_ENDPOINT+"client-app/server-tip";
      const headers = {
        'Content-Type': 'application/json',
        'Token': tokenAPI
      }
      const data = {
        club_id: localStorage.getItem('appcid'),
        booking_request_id: bid,
        tip_amount: 0,
        tip_option:tipPercent,
        tip_id:tipId
      };
      axios.post(saveTipApi, data, {
        headers: headers
      })
      .then(res => {
        if(res.status === 200){
        }
      })
      .catch((error) => {
      });
    }
  }

  const checkCode = (e) => {
    e.preventDefault();
    setErrorMessage('');
    if(promocode !== ''){
      let cid = localStorage.getItem('appcid');
      let promocodevalidApi = process.env.REACT_APP_API_ENDPOINT+"client-app/get-promocode-by-code-club/"+promocode+"/"+cid;
      const headers = {
        'Content-Type': 'application/json',
        'Token': usertoken
      }
      setDisabledBtn(1);
      axios.get(promocodevalidApi, {
        headers: headers
      })
      .then(res => {
        if(res.status === 200){
          if(res.data.promocode_details){
            if(res.data.promocode_details.id){
              let promoid = res.data.promocode_details.id;
              let promocodeApi = process.env.REACT_APP_API_ENDPOINT+"client-app/apply-promocode";
              axios.post(promocodeApi, {booking_id:bid, promocode_id:promoid},{
                headers: headers
              })
              .then(res => {
                setDisabledBtn(0);
                if(res.status === 200){
                  if(res.data.promo_application_details){
                    setNewAmount(res.data.promo_application_details.final_amount);
                    setPromocodeApplied(1);
                  }
                }
              })
              .catch((error) => {
                setDisabledBtn(0);
                if(error.response.data.msg){
                  setErrorMessage(error.response.data.msg);
                }else if(error.response.status === 401){
                  localStorage.clear();
                  navigate({ pathname: '/' });
                }
              });
            }
          }
        }
      })
      .catch((error) => {
        setDisabledBtn(0);
        if(error.response.data.msg){
          setErrorMessage(error.response.data.msg);
        }else if(error.response.status === 401){
          localStorage.clear();
          navigate({ pathname: '/' });
        }
      });
    }
  }

  const removeCode = (e) => {
    e.preventDefault();
    setErrorMessage('');
    if(promocodeApplied){
      let promocodeApi = process.env.REACT_APP_API_ENDPOINT+"client-app/cancel-promocode";
      const headers = {
        'Content-Type': 'application/json',
        'Token': usertoken
      }
      setDisabledBtn(1);
      axios.post(promocodeApi,{booking_id:bid}, {
        headers: headers
      })
      .then(res => {
        setDisabledBtn(0);
        if(res.status === 200){
          setPromocodeApplied(0);
          setNewAmount('');
          setPromoCode('');
        }
      })
      .catch((error) => {
        setDisabledBtn(0);
        if(error.response.data.msg){
          setErrorMessage(error.response.data.msg);
        }else if(error.response.status === 401){
          localStorage.clear();
          navigate({ pathname: '/' });
        }
      });
    }
  }

  const selectTipOption = (e) => {
    e. preventDefault(e);
    setTipPercent(e.target.value);
    setCustomTip('');
    return false;
  }

  const selectCustomTip = (e) => {
    const re = /^(\d)*(\.)?(\d)*$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setCustomTip(e.target.value);
      return false;
    }
    
  }

  let tipValue = 0;
  if(tipPercent === 'custom'){
    if(customTip > 0){
      tipValue = parseFloat(customTip);
    }
  }else if(tipPercent){
    tipValue = parseFloat((tipPercent/100) * amount);
  }

  return (
    <>
    { usertoken ?
        <div className="App-page">
          <section className="topSection">
            <img src={logo} className="App-logo-medium" alt="logo" /><br/>
            <span className="backLink"><Link to={`/customize?mo=${mo}&c=${custom}`}></Link></span>
          </section>
          <section className="bottomSection">
            <label>Review your order</label><br/>
            <form className='orderDetails'>
            <div className='promocodeCont'>
              <strong>Promo Code <span>(if any)</span></strong><br/>
              <input type="text" value={promocode} autoComplete="off" onChange={(e) => setPromoCode(e.target.value)} maxLength="16" placeholder="" />
              {promocodeApplied === 1 ?
                <button id="userRemovePromoCodeBtn" className='removeCode' onClick={removeCode} disabled={(disabledBtn)?'disabled':''}></button>
              :
                <button id="userApplyPromoCodeBtn" className='App-button' onClick={checkCode} disabled={(promocode && !disabledBtn && parseFloat(amount) !== 0)?'':'disabled'}>Apply</button>
              }
            </div><br/><br/>
            <div className='addTipCont'>
              <strong>Add a Tip <span>(100% of this goes to your server)</span></strong><br/>
              <div>
                <button id="userNoTipBtn" value="0" className={((tipPercent === '0' && customTip === '' && tipPercent != '')?'selected':'')} onClick={(e)=>selectTipOption(e)}>No Tip</button>
                <button id="userTip10Btn" value="10" className={((tipPercent === '10' && customTip === '')?'selected':'')} onClick={(e)=>selectTipOption(e)}>10%</button>
                <button id="userTip15Btn" value="15" className={((tipPercent === '15' && customTip === '')?'selected':'')} onClick={(e)=>selectTipOption(e)}>15%</button>
                <button id="userTip20Btn" value="20" className={((tipPercent === '20' && customTip === '')?'selected':'')} onClick={(e)=>selectTipOption(e)}>20%</button>
                <button id="userTipCustomBtn" value="custom" className={((tipPercent === 'custom')?'selected':'')} onClick={(e)=>selectTipOption(e)}>Custom</button>
              </div>
              {
                (tipPercent === "custom") &&
                <div className='customTipCont'>
                  <span>$</span><input id="userCustomTipInput" type="text" value={customTip} autoComplete="off" maxLength="8" placeholder="Enter Custom Amount" onChange={(e)=>selectCustomTip(e)} />
                </div>
              }
            </div><br/><br/>
              <div>
                <strong>Amount</strong><br/>
                <div className='paymentInfoCont'>
                  <div>
                    <strong>Sign:</strong>
                    { newAmount !== '' ?
                      <span><em className='strike'>${amount}</em>&nbsp;&nbsp;${newAmount}</span>
                    :
                      <span>${(amount)}</span>
                    }
                  </div>
                  <div>
                    <strong>Tip:</strong>
                    <span>{tipPercent === ''?'-':'$'+(Math.round(tipValue * 100) / 100)}</span>
                  </div>
                  <div>
                    <strong style={{fontWeight:600}}>Total:</strong>
                    <span style={{fontWeight:600}}>${((newAmount !== ''?newAmount:amount)+tipValue)}</span>
                  </div>
                </div><br/>
              </div>
            <br/><br/>
            {errormsgcode && 
              <div className='error'>{errormsgcode}</div>
            }
            {
             ((newAmount !== '' && (parseFloat(newAmount) + tipValue) === 0) || (parseFloat(amount) === 0 && (parseFloat(amount) + tipValue) === 0))?
                <button id="userReviewSubmitBtn" type="submit" className="App-button" disabled={(disabledBtn)?'disabled':''} onClick={submitButton}>Submit</button>
             :
                <button id="userReviewProceedBtn" type="submit" className="App-button" disabled={(disabledBtn)?'disabled':''} onClick={payNowButton}>Proceed</button>
            }
            <br/>
            </form>
          </section>
        </div>
      :
      <Signin/>
    }
    </>
  );
}