 import axios from 'axios';
import { useState } from 'react';
import { useNavigate, createSearchParams } from 'react-router-dom';
import logo from '../../images/logo.png';
import './Signin.css';
import 'react-phone-number-input/style.css';
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input';

export const Signin = () => {
  console.log("In Signin page");
  const navigate = useNavigate();
  const [value, setValue] = useState();
  const [email, setEmail] = useState('');
  const [errormsg, setErrorMessage] = useState('');
  const [code, setCode] = useState('');
  const [errormsgcode, setCodeErrorMessage] = useState('');
  const [pagestep, setPageStep] = useState(1);
  const [signupType, setSignUpType] = useState('m');
  //const newfow = localStorage.getItem('appusernewflow');
  const newflowSession = sessionStorage.getItem('appusernewflow');
  const [apiprocessingFlag, setAPIProcessingFlag] = useState(false);

  const signinSubmit = (e) => {
    e.preventDefault();
    setErrorMessage("");
    if(signupType === 'e'){
      if(!email){
        setErrorMessage("E-Mail is required.");
        return;
      }else if (!isValidEmail(email)){
        setErrorMessage("E-Mail is invalid.");
        return;
      }
    }else{
      if(!value){
        setErrorMessage("Mobile Number is required.");
        return;
      }else if(!(isValidPhoneNumber(value))){
        setErrorMessage("Mobile Number is invalid.");
        return;
      }
    }
    if(apiprocessingFlag){ return; }
    setAPIProcessingFlag(true);
    let signinApi = '';
    let apiData = {};
    if(signupType === 'm'){ // mobile signin
      signinApi = process.env.REACT_APP_API_ENDPOINT+"client-app/mobile-login";
      apiData = {mobile_number: value};
    }else{ // email signin
      signinApi = process.env.REACT_APP_API_ENDPOINT+"client-app/email-login";
      apiData = {email: email};
    }
    axios.post(signinApi, apiData)
    .then(res => {
      if(res.status === 200){
        setPageStep(2);
      }
      setAPIProcessingFlag(false);
    })
    .catch((error) => {
      if(error.response.data.msg){
        setErrorMessage(error.response.data.msg);
      }
      setAPIProcessingFlag(false);
    });
  }

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const verifyCodeSubmit = (e) => {
    e.preventDefault();
    setCodeErrorMessage("");
    if(!code){
      setCodeErrorMessage("Code is required.");
      return;
    }

    let verifyCodeApi = '';
    let verifyDataApi = {};
    if(signupType === 'm'){ // mobile signin
      verifyCodeApi = process.env.REACT_APP_API_ENDPOINT+"client-app/verify-mobile-otp";
      verifyDataApi = { mobile_number: value, otp: code };
    }else{ // email signin
      verifyCodeApi = process.env.REACT_APP_API_ENDPOINT+"client-app/verify-email-otp";
      verifyDataApi = { email: email, otp: code };
    }
    axios.post(verifyCodeApi, verifyDataApi)
    .then(res => {
      if(res.status === 200){
        localStorage.setItem('appusertoken',res.data.token);
        if(sessionStorage.getItem('appcid') !== ''){
          localStorage.setItem('appcid',sessionStorage.getItem('appcid'));
        }
        if(res.data.new_user){
          navigate({
            pathname: '/profile',
            search: createSearchParams({
              uid: res.data.user_data.id
            }).toString()
          });
        }else{
          smartlook('identify', res.data.user_data.id, {"name": res.data.user_data.first_name});
          if(newflowSession){
            if(newflowSession && sessionStorage.getItem('appusertblid') !== ''){
              localStorage.setItem('appusertblid',sessionStorage.getItem('appusertblid'));
              //localStorage.setItem('appusernewflow',sessionStorage.getItem('appusernewflow'));
            }
            navigate({ pathname: '/digital-sign'});
          }else{
            navigate({ pathname: '/scan'});
          }
        }
      }
    })
    .catch((error) => {
      if(error.response.data.msg){
        setCodeErrorMessage(error.response.data.msg);
      }
    });
  }

  const backClicked = (e) => {
    setPageStep(1);
    setCodeErrorMessage("");
  }

  const changeSignup = (e) => {
    setErrorMessage("");
    if(signupType === 'm'){
      setSignUpType('e');
    }else{
      setSignUpType('m');
    }
  }

  return (
    <>
    { pagestep === 1 ?
      <div className="App-page">
          <img src={logo} className="App-logo" alt="logo" /><br/>
          <div className="outerCont">
            <form onSubmit={signinSubmit}>
              <label>{signupType === 'm' ? 'Mobile Number':'E-Mail'}</label>
              {signupType === 'm' ? 
                <PhoneInput placeholder="Mobile Number" defaultCountry="US" value={value} onChange={setValue}/>
              :
                <div><input type="text" value={email} autoComplete="off" onChange={(e) => setEmail(e.target.value)} maxLength="50" placeholder="example@email.com" /></div>
              }
              <br/><br/>
              {errormsg && 
                <div className='error'>{errormsg}</div>
              }
              <button id="userSignInBtn" type="submit" className="App-button">Next</button><br/>
            </form>
          </div>
          <button id="userSignInChangeLink" className="App-text-link" onClick={(e) => changeSignup()}>Sign in with {signupType === 'm' ? 'email':'mobile number'} instead</button><br/>
      </div>
      :
      <div className="App-page">
          <img src={logo} className="App-logo" alt="logo" /><br/><br/><br/>
          <span className="backLink" onClick={(e) => backClicked()}></span>
          <div className="outerCont">
            <form style={{marginTop: "-30px"}} onSubmit={verifyCodeSubmit}>
              <label>Verify your {signupType === 'e' ? 'e-mail':'mobile number'} <br/>with the code we just <br/> {signupType === 'e' ? 'sent':'messaged'} you</label>
              <input type="text" value={code} autoComplete="off" onChange={(e) => setCode(e.target.value)} maxLength="10" placeholder="code" /><br/><br/>
              {errormsgcode && 
                <div className='error'>{errormsgcode}</div>
              }
              <button id="userVerifyCodeBtn" type="submit" className="App-button">Next</button><br/>
            </form>
          </div>
      </div>
    }
    </>
  );
}