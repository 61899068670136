import { Signin } from '../signin/Signin';
import {Link} from 'react-router-dom';
import logo from '../../images/logo.png';
import paymentsuccess from '../../images/big_tick.png';
import '../updatesuccess/UpdateSuccess.css';

export const UpdateSuccess = () => {
  console.log("In Update Success page");
  const usertoken = localStorage.getItem('appusertoken');

  return (
    <>
    { usertoken ?
        <div className="App-page">
          <img src={logo} className="App-logo" alt="logo" /><br/><br/>
          <span className="gridLink"><Link to="/digital-sign"></Link></span>
            <div className="outerCont successCont">
              <img src={paymentsuccess} alt="Successful" width="60%"/><br/>
              <h4>Order Updated!</h4>
              <p>Your sign is being reviewed and will come out with your next bottle order. Please check your texts/email for progress updates.</p>
            </div>
        </div>
      :
      <Signin/>
    }
    </>
  );
}