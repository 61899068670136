import React, { useCallback, useRef, useState } from "react";
import {useNavigate, createSearchParams} from 'react-router-dom';
import axios from 'axios';
import Webcam from "react-webcam";
import { CircularProgressbar } from 'react-circular-progressbar';
import { UploadingProgressBar } from "./UploadingProgressBar";

export default function WebcamVideo() {
  const navigate = useNavigate();
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [progressBarValue, setProgressBarValue] = useState(0);
  const [uploadVideoBtnState, setUploadVideoBtnState] = useState(1);
  let progressInterval = useRef(null);
  let isTouchDevice = false;
  const FACING_MODE_USER = "user";
  const FACING_MODE_ENVIRONMENT = "environment";
  const [facingMode, setFacingMode] = useState(FACING_MODE_USER);
  const [uploadProgressBarValue, setUploadProgressBarValue] = useState(0);

  if ("ontouchstart" in document.documentElement)
  {
    isTouchDevice = true;
  }

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStartCaptureClick = useCallback(() => {
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/webm",
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef, handleDataAvailable]);

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
  }, [mediaRecorderRef, setCapturing]);

  let videoConstraints = {
    width: { min: window.innerWidth },
    height: { min: 600 },
    facingMode: facingMode
  };

  const captureStartEvent = (e) => {
    e.preventDefault();
    handleStartCaptureClick();
    if(!isTouchDevice){
      clearInterval(progressInterval.current);
      let time = 0;
      setProgressBarValue(time);
      progressInterval.current = setInterval(() => {
        time = time + 1;
        setProgressBarValue(time);
        if(time >= 60){
          clearInterval(progressInterval.current);
          handleStopCaptureClick();
        }
      }, 1000);
    }else{
      clearInterval(progressInterval.current);
      let time = 60;
      setProgressBarValue(time);
      progressInterval.current = setInterval(() => {
        time = time - 1;
        setProgressBarValue(time);
        if(time <= 0){
          clearInterval(progressInterval.current);
          handleStopCaptureClick();
        }
      }, 1000);
    }
  }

  const captureStopEvent = (e) => {
    e.preventDefault();
    handleStopCaptureClick();
    clearInterval(progressInterval.current);
  }

  const uploadVideo = (e) => {
    setUploadVideoBtnState(0);
    fetch(videoUrl)
      .then(res => res.blob()).then(blob => {
        let uploadApi = process.env.REACT_APP_API_ENDPOINT+"client-app/upload";
        const usertoken = localStorage.getItem('appusertoken');
        const formData = new FormData();
        formData.append('file', blob, "captured-video.webm");
        formData.append("type", "video");
        axios.post(uploadApi, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Token': usertoken
            },
            onUploadProgress: (progressEvent) => {
              setUploadProgressBarValue(parseInt(progressEvent.progress * 100));
            }
        })
        .then(res => {
          setUploadVideoBtnState(1);
          if(res.status === 200){
            if(res.data.id){
              navigate({
                pathname: '/preview-custom-selection',
                search: createSearchParams({
                  co: res.data.id
                }).toString()
              });
            }
          }
        })
        .catch((error) => {
          setUploadVideoBtnState(1);
          if(error.response.status === 401){
            localStorage.clear();
            navigate({ pathname: '/' });
          }
        });
      });
  }

  const switchCamera = useCallback(() => {
    setFacingMode(
      prevState =>
        prevState === FACING_MODE_USER
          ? FACING_MODE_ENVIRONMENT
          : FACING_MODE_USER
    );
    document.getElementById('cameraCont').style.display = 'none';
    setTimeout(function(){
      document.getElementById('cameraCont').style.display = 'block';
    },1500);
  }, []);

  let videoUrl = '';
  if(recordedChunks.length){
    var blob = new Blob(recordedChunks, {
      type: 'video/webm'
      });
      videoUrl = URL.createObjectURL(blob);
  }

  return (
    <div className="container">
      {
        (videoUrl === "") ?
        <>
          <Webcam
            id="cameraCont"
            height={600}
            width={window.innerWidth}
            audio={false}
            ref={webcamRef}
            videoConstraints={videoConstraints}
            mirrored={(facingMode === FACING_MODE_USER)?true:false}
          />
          { !capturing && !recordedChunks.length &&
            <div className="switchBtn">
              <button onClick={switchCamera}></button>
            </div>
          }
        </>
        :
          <video src={videoUrl} height={600} width={window.innerWidth} controls></video>
      }
      
      {capturing || !recordedChunks.length ? (
        <div className="captureBtn">
          {
            (isTouchDevice) ? 
              <div>
                <small>{(capturing?'Stop Recording':'Start Recording')}</small><br/>
                <button className={(capturing)?'stopIcon':''} onClick={(e) => (capturing)?captureStopEvent(e):captureStartEvent(e)}>{(capturing)?progressBarValue:''}</button>
              </div>
            :
            <div>
              <small>Hold for video</small><br/>
              <button></button>
              <div className="progressRingCont" onMouseDown={(e) => captureStartEvent(e)} onMouseUp={(e) => captureStopEvent(e)} onMouseLeave={(e) => captureStopEvent(e)}>
                <CircularProgressbar 
                  value={progressBarValue}
                  styles={{
                    path: {
                      stroke: `rgba(255, 78, 0, 1)`
                    },
                    trail: {
                      stroke: '#fff'
                    }
                  }}
                  />
              </div>
            </div>
          }
        </div>
      ) : recordedChunks.length > 0 && (
        <div className="uploadBottomBtn">
          { (!uploadVideoBtnState) &&
            <UploadingProgressBar progressBarValue={uploadProgressBarValue} ></UploadingProgressBar>
          }
          <button className="App-button" disabled={(uploadVideoBtnState)?'':'disabled'} onClick={(e) => uploadVideo(e)}>Upload</button>
        </div>
      )}
    </div>
  );
}