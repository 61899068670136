import axios from 'axios';
import { useState, useEffect } from 'react';
import {useNavigate, createSearchParams} from 'react-router-dom';
import logo from '../../images/logo.png';
import {SignTypeItem} from '../../components/SignTypeItem'
import '../digitalsign/DigitalSign.css'
import { Signin } from '../signin/Signin';
import photoIcon from '../../images/photo-icon.png';
import videoIcon from '../../images/upload-icon.png';

export const DigitalSign = () => {
  console.log("In Digital Sign page");

  const navigate = useNavigate();
  const usertoken = localStorage.getItem('appusertoken');
  // const newflow = localStorage.getItem('appusernewflow');
  const [dsSelList, setDSListData] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState('');

  useEffect(() => {
    const tokenAPI = localStorage.getItem('appusertoken');
    const clubID = localStorage.getItem('appcid');
    let dsSelListApi = process.env.REACT_APP_API_ENDPOINT+"client-app/get-digital-sign-categories-club/"+clubID;
    const headers = {
      'Content-Type': 'application/json',
      'Token': tokenAPI
    }
    axios.get(dsSelListApi, {
      headers: headers
    })
    .then(res => {
      if(res.status === 200){
        if(res.data){
          setDSListData(res.data.digital_sign_type);
        }
      }
    })
    .catch((error) => {
      if(error.response.status === 401){
        localStorage.clear();
        navigate({ pathname: '/' });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectDSCatgory = (index) => {
    setSelectedIndex(index);
    if(index >= 0 && dsSelList.length > selectedIndex){
      navigate({
        pathname: '/digital-sign-list',
        search: createSearchParams({
          dsc: dsSelList[index].id
        }).toString()
      });
    }else if(index === '-1' || index === '-2'){
      navigate({ 
        pathname: '/user-media',
        search: createSearchParams({
          t: (index === '-1')?'2':'3'
        }).toString()
      });
    }
  }

  // const nextBtnClicked = (e) => {
  //   if(selectedIndex !== '' && selectedIndex !== '-1' && dsSelList.length > selectedIndex){
  //     navigate({
  //       pathname: '/digital-sign-list',
  //       search: createSearchParams({
  //         dsc: dsSelList[selectedIndex].id
  //       }).toString()
  //     });
  //   }else if(selectedIndex === '-1'){
  //     navigate({ pathname: '/user-media'});
  //   }
  // }

  return (
    <>
    {usertoken ? 
      <div className="App-page">
          <section className="topSection">
            <img src={logo} className="App-logo-medium" alt="logo" /><br/>
            {/* <span className="backLink"><Link to={(newflow)?'/':'/scan'}></Link></span> */}
          </section>
          {dsSelList.length ?
            <section className="bottomSection">
              <label>Select the type of digital sign you would like</label><br/>
              <div className="signBoxCont">
              <SignTypeItem key="-1" index="-1" click={selectDSCatgory} class="signBox" customclass={(selectedIndex === "-1")?'active bg60':'bg60'}  image={photoIcon} label="Your Photo" cost=""></SignTypeItem>
              <SignTypeItem key="-2" index="-2" click={selectDSCatgory} class="signBox" customclass={(selectedIndex === "-2")?'active bg60':'bg60'}  image={videoIcon} label="Your Video" cost=""></SignTypeItem>
                {
                  dsSelList.map((category,i) =>
                    <SignTypeItem key={i} index={i} click={selectDSCatgory} class="signBox" customclass={(i === selectedIndex)?'active':''} image={category.image_url} label={category.title} cost=""></SignTypeItem>
                  )
                }
              </div>
              <small className="note">Note: all options allow custom text</small><br/><br/>
              {/* <button className="App-button" disabled={selectedIndex !== ''?'':'disabled'} onClick={nextBtnClicked}>Next</button> */}
            </section>
            :
            <section className="bottomSection">
            </section>
          }
      </div>
      :
      <Signin/>
    }
    </>
  );
}