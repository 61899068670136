import { useState } from 'react';
import {useNavigate, Link, createSearchParams} from 'react-router-dom';
import {QrReader} from 'react-qr-reader';
import logo from '../../images/logo.png';
import qrcode from '../../images/barcode.png'
import { Signin } from '../signin/Signin';

export const Scan = () => {
  console.log("In Scan page...");

  const navigate = useNavigate();
  const usertoken = localStorage.getItem('appusertoken');
  const [pagestep, setPageStep] = useState(1);
  const [qrError, setQRError] = useState('');

  const scanQRCode = (e) => {
    setQRError('');
    setPageStep(2);
  }

  const backClicked = (e) => {
    setPageStep(1);
  }

  const scanConfirmation = (data) => {
    console.log(data);
    try {
      const validateData = (JSON.parse(data));
      if(validateData.table_id){
          setPageStep(1);
          navigate({
            pathname: '/confirm-table',
            search: createSearchParams({
              tableID: validateData.table_id
            }).toString()
          });
      }else{ // json data not valid
        setQRError("QR code not valid, please try again");
      }
    } catch (e) {
        setQRError("QR code not valid, please try again");
    } 
  }

  return (
    <>
    { usertoken ?
      <div className="App-page">
          <img src={logo} className="App-logo" alt="logo" /><br/><br/>
          { pagestep === 1 ?
            <span className="backLink"><Link to="/"></Link></span>
            :
            <span className="backLink" onClick={(e) => backClicked()}></span>
          }
          <div className="outerCont">
            <form style={{marginTop: "-55px" , textAlign: "center"}}>
              <label>Scan the QR code of <br/> your VIP table:</label><br/>
              { pagestep === 1 ?
                  <div>
                      <img src={qrcode} width="150px" alt="QR Code" /><br/><br/>
                      <button id="userScanCodeBtn" type="submit" className="App-button" onClick={scanQRCode}>Scan</button><br/>
                  </div>
                :
                  <div>
                    <QrReader delay={1000} className="scannerCont" key="environment"
                      constraints={{ facingMode: 'environment' }}
                      onResult={(result, error) => {
                        if (!!result) {
                          scanConfirmation(result?.text);
                        }
                        if (!!error) {
                          console.info(error);
                        }
                      }}
                    />
                    <p>{qrError}</p>
                  </div>
              }
            </form>
          </div>
      </div>
      :
      <Signin/>
    }
    </>
  );
}