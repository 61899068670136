 import axios from 'axios';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import logo from '../../images/logo.png';
import { Signin } from '../signin/Signin';
import './Profile.css';

export const Profile = () => {
  console.log("In Profile page");
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const uid = queryParams.get('uid');
  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [errormsg, setErrorMessage] = useState('');

  const usertoken = localStorage.getItem('appusertoken');
  //const newflow = localStorage.getItem('appusernewflow');
  const newflowSession = sessionStorage.getItem('appusernewflow');

  const saveProfile = (e) => {
    e.preventDefault();
    setErrorMessage("");
    //Validations
    if(firstname === ''){
      setErrorMessage("First Name is required.");
      return;
    }else if(lastname === ''){
      setErrorMessage("Last Name is required.");
      return;
    }else if(day === '' || month === '' || year === ''){
      if(day === '' && month === '' && year === ''){
        // do nothing
      }else{
        setErrorMessage("Please enter a valid DOB.");
        return;
      }
    }
    const profileApi = process.env.REACT_APP_API_ENDPOINT+"client-app/me";
    const headers = {
      'Content-Type': 'application/json',
      'Token': usertoken
    }
    axios.post(profileApi, { first_name: firstname, last_name: lastname, gender:null, day: (day!==''?parseInt(day):null), month: (month!==''?parseInt(month):null), year: (year!==''?parseInt(year):null)}, { headers })
    .then(res => {
      if(res.status === 200){
        smartlook('identify', uid, {"name": firstname});
        if(newflowSession){
          navigate({ pathname: '/digital-sign'});
        }else{
          navigate({ pathname: '/scan'});
        }
      }
    })
    .catch((error) => {
      if(error.response.data.msg){
        setErrorMessage(error.response.data.msg);
      }else if(error.response.status === 401){
        localStorage.clear();
        navigate({ pathname: '/' });
      }
    });
  }

  const backFromProfile = (e) => {
    e.preventDefault();
    localStorage.clear();
    navigate({ pathname: '/' });
  }

  return (
    <>
      { usertoken ?
      <div className="App-page">
        <img src={logo} className="App-logo-medium" alt="logo" /><br/>
        <span className="backLink" onClick={backFromProfile}></span>
        <div className="profileCont">
          <br/><strong>Create a Profile</strong><br/><br/>
          <form onSubmit={saveProfile}>
            <div><input type="text" value={firstname} autoComplete="off" onChange={(e) => setFirstName(e.target.value)} maxLength="50" placeholder="First Name" /></div>
            <br/><br/>
            <div><input type="text" value={lastname} autoComplete="off" onChange={(e) => setLastName(e.target.value)} maxLength="50" placeholder="Last Name" /></div>
            <br/><br/>
            <div>       
              <label className="dobLabel">Date of Birth (optional):</label>   
              <fieldset>
                <span>
                  {/* <label>Day</label> */}
                  <input type="number" min="1" max="31" size="2" maxLength="2" placeholder="DD" value={day} autoComplete="off" onChange={(e) => setDay(e.target.value)} />
                </span>
                <span>
                  {/* <label>Month</label> */}
                  <input type="number" min="1" max="12" size="2" maxLength="2" placeholder="MM" value={month} autoComplete="off" onChange={(e) => setMonth(e.target.value)} />
                </span>
                <span>
                  {/* <label>Year</label> */}
                  <input type="number" min="1950" max={(new Date().getFullYear())} size="4" maxLength="4" placeholder="YYYY" value={year} autoComplete="off" onChange={(e) => setYear(e.target.value)} />
                </span>
              </fieldset>
            </div>
            <br/><br/><br/>
            {errormsg && 
              <div className='error'>{errormsg}</div>
            }
            <button id="userProfileSaveBtn" type="submit" className="App-button">Save</button><br/>
          </form>
        </div>
      </div>
      :
      <Signin/>
      }
    </>
  );
}