import begin from '../images/begin_icon.png';
import end from '../images/end_icon.png';
export const DashboardInProgressListItem = (props) => {

  const data = props.data;
  const isPlaying = props.playing;
  return (
    <>
        {/* <dt>{props.name}</dt> */}
        <dt></dt>
        {
          data.length ?
          data.map((listItem) =>
            <dd key={listItem.ID}>
              <div>
              <label>Table {listItem.Table.TableID}</label>
              <small>{listItem.Table.UserName} | {
              new Intl.DateTimeFormat('default', {day: '2-digit',month: 'short', hour: '2-digit', minute: '2-digit'}).format(new Date(listItem.Table.DateOfBooking))
              }</small>
              <small>Amount: ${listItem.Amount}</small> 
              </div>
              <div className="multipleBtns">
                {(listItem.Status === 5)?
                  <button className="Admin-button clientAppStopBtn" style={{color:"red"}} onClick={() => props.update(listItem.ID,3)}><img width="10px" src={end} alt="stop" /> Stop</button>
                :
                  <button className="Admin-button clientAppPlayBtn" disabled={isPlaying} onClick={() => props.update(listItem.ID,5)}><img width="10px" src={begin} alt="play" /> Play</button>
                }
                <button className="Admin-button clientAppPreviewBtn" onClick={() => props.click(listItem.ID)}>Preview</button>
              </div>
              {
                  listItem.ClubScreens && listItem.ClubScreens.length &&
                  <p className="screenInfo">Playing on 
                  {
                    listItem.ClubScreens.map((screen,i) =>
                      ((i==0)?' ':', ')+screen.screen_title
                    )
                  }
                  </p>
                }
            </dd>
          ) 
          :
          <dd>
            <p className='textCenter'><small>You don't have any in-progress orders yet.</small></p>
          </dd>
        }
    </>
  );
}