import axios from 'axios';
import { useState, useEffect } from 'react';
import {Link, useSearchParams, createSearchParams, useNavigate} from 'react-router-dom';
import '../previewcustomselection/PreviewCustomSelection.css'
import { Signin } from '../signin/Signin';

export const PreviewCustomSelection = () => {
  console.log("In Preview Custom Selection page");

  const navigate = useNavigate();
  const usertoken = localStorage.getItem('appusertoken');
  const [queryParams] = useSearchParams();
  const co = queryParams.get('co');
  const [coData, setcoData] = useState([]);

  useEffect(() => {
    const tokenAPI = localStorage.getItem('appusertoken');
    let coPreviewApi = process.env.REACT_APP_API_ENDPOINT+"client-app/get-custom-media/"+co;
    const headers = {
      'Content-Type': 'application/json',
      'Token': tokenAPI
    }
    axios.get(coPreviewApi, {
      headers: headers
    })
    .then(res => {
      if(res.status === 200){
        if(res.data){
          setcoData(res.data.custom_media_details);
        }
      }
    })
    .catch((error) => {
      if(error.response.status === 401){
        localStorage.clear();
        navigate({ pathname: '/' });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const customizeBtnClicked = (e) => {
      navigate({
        pathname: '/customize',
        search: createSearchParams({
          mo: co,
          c: 1
        }).toString()
      });
  }
  console.log(coData);
  return (
    <>
    {usertoken ? 
      <div className="App-page">
        <span className="backLink"><Link to="/user-media"></Link></span>
        {
        coData.id ? 
          ((coData.mime).indexOf('video') > -1) ?
          <video src={coData.url} width="100%" height="auto" playsInline autoPlay muted></video> 
          :((coData.mime).indexOf('image') > -1) ?
          <div className="previewCustomCont" style={{ backgroundImage:'url('+coData.url+')' }}> </div>
          :
          <div></div>
        :
        <div></div>
        }
       <br/>
        <button id="userCustomCustomizeBtn" className="customBtn App-button" onClick={customizeBtnClicked}>Customize</button>
      </div>
      :
      <Signin/>
    }
    </>
  );
}