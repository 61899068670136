import axios from 'axios';
import { useState, useEffect } from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import { Signin } from '../signin/Signin';
import logo from '../../images/logo.png';

export const Welcome = () => {
  console.log("In Welcome page");

  const navigate = useNavigate();
  const [queryParams] = useSearchParams();

  let tableID = queryParams.get('t');
  const qrArr = ['84700430e0f8-11ee-aa4c-0ed5aeba8965','84701347e0f8-11ee-aa4c-0ed5aeba8965','84702021e0f8-11ee-aa4c-0ed5aeba8965','84702111e0f8-11ee-aa4c-0ed5aeba8965','84702357e0f8-11ee-aa4c-0ed5aeba8965','84702634e0f8-11ee-aa4c-0ed5aeba8965','84702823e0f8-11ee-aa4c-0ed5aeba8965'];
  const qrArrCorrected = ['84700430-e0f8-11ee-aa4c-0ed5aeba8965','84701347-e0f8-11ee-aa4c-0ed5aeba8965','84702021-e0f8-11ee-aa4c-0ed5aeba8965','84702111-e0f8-11ee-aa4c-0ed5aeba8965','84702357-e0f8-11ee-aa4c-0ed5aeba8965','84702634-e0f8-11ee-aa4c-0ed5aeba8965','84702823-e0f8-11ee-aa4c-0ed5aeba8965'];
  if(qrArr.indexOf(tableID) > -1){  // change incorrect QR code 
    tableID = qrArrCorrected[qrArr.indexOf(tableID)];
  }

  //const tableID = queryParams.get('t');
  const usertoken = localStorage.getItem('appusertoken');
  const [tableData, setTableData] = useState([]);
  const [apiErrorMsg, setApiErrorMsg] = useState('');

  useEffect(() => {
    const tokenAPI = localStorage.getItem('appusertoken');
    let tableApi = process.env.REACT_APP_API_ENDPOINT+"client-app/club-table-info-newflow/"+tableID;
    const headers = {
      'Content-Type': 'application/json',
      'Token': tokenAPI
    }
    axios.get(tableApi, {
      headers: headers
    })
    .then(res => {
      if(res.status === 200){
        if(res.data.table_data){
          setTableData(res.data.table_data);
          sessionStorage.setItem('appusernewflow',1);
          sessionStorage.setItem('appusertblid',tableID);
          sessionStorage.setItem('appcid',res.data.table_data.club_id);
        }
      }
    })
    .catch((error) => {
      if(error.response.data.msg){
        setApiErrorMsg(error.response.data.msg);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const confirm = (e) => {
    localStorage.setItem('appusertblid',tableID);
    localStorage.setItem('appcid',tableData.club_id);
    sessionStorage.setItem('appusernewflow',1);
    setTimeout(function() {
      if(!usertoken){
        navigate({ pathname: '/'});
      }else{
        navigate({ pathname: '/digital-sign'});
      }
    }, 500);
  }

  return (
    <>
    { 1 ?
      <div className="App-page">
          <section className="topSection">
            <img src={logo} className="App-logo-medium" alt="logo" /><br/>
          </section>
          { (tableData.table_number && apiErrorMsg === '') ?
            <section className="bottomSection">
              <br/><br/><br/>
              <img src={tableData.club_logo} className="App-logo-medium imgHAuto" alt="logo" />
              <br/><br/><br/><br/><br/>
              <p>
                <strong>Table # {tableData.table_number}</strong><br/>
                <small>City: {tableData.club_city}</small>
              </p>
              <br/><br/>
                <button id="userWelcomeConfirmBtn" className="App-button" onClick={confirm}>Confirm</button>
              <br/>
            </section>
            :
            (apiErrorMsg !== '') ? 
            <section className="bottomSection">
              <br/><br/><br/>
              <p>{apiErrorMsg}</p>
              <br/>
            </section>
            :
            <section className="bottomSection"></section>
          }
      </div>
      :
      <Signin/>
    }
    </>
  );
}