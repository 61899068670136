import React, { useCallback, useRef, useState } from "react";
import {useNavigate, createSearchParams} from 'react-router-dom';
import Webcam from "react-webcam";
import axios from 'axios';
import { UploadingProgressBar } from "./UploadingProgressBar";

function WebcamImage() {
  const navigate = useNavigate();
  const [img, setImg] = useState(null);
  const webcamRef = useRef(null);
  const [uploadImgBtnState, setUploadImgBtnState] = useState(1);
  const FACING_MODE_USER = "user";
  const FACING_MODE_ENVIRONMENT = "environment";
  const [facingMode, setFacingMode] = useState(FACING_MODE_USER);
  const [uploadProgressBarValue, setUploadProgressBarValue] = useState(0);

  let videoConstraints = {
    width: { min: window.innerWidth },
    height: { min: 500 },
    facingMode: facingMode
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImg(imageSrc);
  }, [webcamRef]);

  const uploadImage = (e) => {
    setUploadImgBtnState(0);
    fetch(img)
      .then(res => res.blob()).then(blob => {
        let uploadApi = process.env.REACT_APP_API_ENDPOINT+"client-app/upload";
        const usertoken = localStorage.getItem('appusertoken');
        const formData = new FormData();
        formData.append('file', blob, "captured-image.jpg");
        formData.append("type", "image");
        axios.post(uploadApi, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Token': usertoken
            },
            onUploadProgress: (progressEvent) => {
              setUploadProgressBarValue(parseInt(progressEvent.progress * 100));
            }
        })
        .then(res => {
          setUploadImgBtnState(1);
          if(res.status === 200){
            if(res.data.id){
              navigate({
                pathname: '/preview-custom-selection',
                search: createSearchParams({
                  co: res.data.id
                }).toString()
              });
            }
          }
        })
        .catch((error) => {
          setUploadImgBtnState(1);
          if(error.response.status === 401){
            localStorage.clear();
            navigate({ pathname: '/' });
          }
        });
      });
  }

  const switchCamera = useCallback(() => {
    setFacingMode(
      prevState =>
        prevState === FACING_MODE_USER
          ? FACING_MODE_ENVIRONMENT
          : FACING_MODE_USER
    );
    document.getElementById('cameraCont').style.display = 'none';
    setTimeout(function(){
      document.getElementById('cameraCont').style.display = 'block';
    },1500);
  }, []);

  return (
    <div className="container">
      {img === null ? (
        <>
          <Webcam
            id="cameraCont"
            audio={false}
            height={600}
            width={window.innerWidth}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            screenshotQuality="1"
            videoConstraints={videoConstraints}
            mirrored={(facingMode === FACING_MODE_USER)?true:false}
          />
          <div className="captureBtn">
            <small>Tap for photo</small><br/>
            <button onClick={capture}></button>
          </div>
          {
            <div className="switchBtn">
              <button onClick={switchCamera}></button>
            </div>
          }
        </>
      ) : (
        <>
          <img src={img} alt="screenshot" width="100%" />
          <div className="uploadBottomBtn">
            { (!uploadImgBtnState) &&
              <UploadingProgressBar progressBarValue={uploadProgressBarValue} ></UploadingProgressBar>
            }
            <button className="App-button" disabled={(uploadImgBtnState)?'':'disabled'} onClick={(e) => uploadImage(e)}>Upload</button>
          </div>
        </>
      )}
    </div>
  );
}

export default WebcamImage;