import { Link } from 'react-router-dom';
import axios from 'axios';
import {useNavigate, useSearchParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Login } from "../../pages/login/Login";
import './Preview.css';

export const Preview = () => {
 console.log("In Preview page");

 const navigate = useNavigate();
 const [queryParams] = useSearchParams();
 const previewId = queryParams.get('id');
 const [previewData, setPreviewData] = useState([]);
 const token = localStorage.getItem('usertoken');
 const userRole = localStorage.getItem('userrole');
 const [errormsg, setErrorMessage] = useState('');
 const [rejectionState, setRejectionState] = useState(false);
 const [reason, setReason] = useState('');
 const [otherReasonText, setOtherReasonText] = useState('');
 const [reasonError, setReasonError] = useState('');
 const [updateTop, setUpdateTop] = useState(0);

 const changeStatus = (id,status) => {
  const tokenAPI = localStorage.getItem('usertoken');
  let changeStatusApi = process.env.REACT_APP_API_ENDPOINT+"admin/change-status/"+id+"/"+status;
  const headers = {
    'Content-Type': 'application/json',
    'Token': tokenAPI
  }
  axios.put(changeStatusApi, {},{
    headers: headers
  })
  .then(res => {
    if(res.status === 200){
      navigate({pathname: '/dashboard'});
    }
  })
  .catch((error) => {
    if(error.response.data.msg){
      setErrorMessage(error.response.data.msg);
    }else if(error.response.status === 401 || error.response.status === 400){
      localStorage.removeItem('usertoken');
      navigate({ pathname: '/admin' });
    }
  });
}

useEffect(() => {
  const tokenAPI = localStorage.getItem('usertoken');
  let previewApi = process.env.REACT_APP_API_ENDPOINT+"admin/table-request/"+previewId;
  const headers = {
    'Content-Type': 'application/json',
    'Token': tokenAPI
  }
  axios.get(previewApi, {
    headers: headers
  })
  .then(res => {
    if(res.status === 200){
      setPreviewData(res.data);
    }
  })
  .catch((error) => {
    if(error.response.status === 401 || error.response.status === 400){
      localStorage.removeItem('usertoken');
      navigate({ pathname: '/admin' });
    }
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

  function createHTMLContent(text){
    return {__html: text}
  }

  const showRejectionView = () => {
    setReason('');
    setOtherReasonText('');
    setReasonError('');
    setRejectionState(true);
  }

  const submitRejection = () => {
    setReasonError(0);
    if(reason !== ''){
        let otherText = '';
        if(reason === 'Other'){
          if(otherReasonText === ''){
            setReasonError(1);
            return;
          }else{
            otherText = otherReasonText;
          }
        }
        let rejectReasonApi = process.env.REACT_APP_API_ENDPOINT+"admin/reject/"+previewId;
        const tokenAPI = localStorage.getItem('usertoken');
        const headers = {
          'Content-Type': 'application/json',
          'Token': tokenAPI
        }
        axios.post(rejectReasonApi, {'rejected_reason_title':reason, 'rejected_reason_desc':otherText}, {
          headers: headers
        })
        .then(res => {
          if(res.status === 200){
            changeStatus(previewId,4);
          }
        })
        .catch((error) => {
          if(error.response.data.msg){
            setErrorMessage(error.response.data.msg);
          }else if(error.response.status === 401 || error.response.status === 400){
            localStorage.removeItem('usertoken');
            navigate({ pathname: '/admin' });
          }
        });
    }
  }

  const mediaLoaded = () => {  // media = video
    setUpdateTop(1);
  };

  let customizedText = '';
  let mediaContentObj = {};
  let multiTextBoxesMediaContentArr = [];
  if(Object.keys(previewData).length){
    const mediaContent = previewData.request_media_overlay;
    mediaContentObj = JSON.parse(mediaContent);
    if(mediaContentObj.data){
      if(mediaContentObj.data.length){ // has multiple textboxes
        multiTextBoxesMediaContentArr = JSON.parse(mediaContent).data;
        mediaContentObj = {};
        if(updateTop){
          for(let l=0; l<multiTextBoxesMediaContentArr.length; l++){
            if(multiTextBoxesMediaContentArr[l].parentStyles.top && multiTextBoxesMediaContentArr[l].relativeTop){ // Adjust top due to blank black spaces at top/bottom of some devices
              let relativeTop = parseInt(multiTextBoxesMediaContentArr[l].relativeTop.replace('px',''));
              if(document.getElementById('mediaContDiv')){
                let previewMediaFrame = document.getElementById('mediaContDiv').getBoundingClientRect();
                if(previewMediaFrame.height){
                  let updatedTop = parseInt((relativeTop/multiTextBoxesMediaContentArr[l].mediaObjDimensions.height*previewMediaFrame.height) + previewMediaFrame.top);
                  multiTextBoxesMediaContentArr[l].parentStyles.top = updatedTop+'px';
                }
              }
            }
          }
        }
      }else{
        mediaContentObj = {};
      }
    }
    if(Object.keys(mediaContentObj).length){
      customizedText = mediaContentObj.text;
    }
    if(previewData.request_type !== 102 && previewData.request_media && !updateTop){ // media = photo
        setUpdateTop(1);
    }
  }

  if(errormsg !== ''){
    setTimeout(() => {
      setErrorMessage('');
      window.location.reload();
    }, 5000);
  }

  return (
    <>
      { token && previewData ?
      <div className="Admin-page">
        <div className="mediaCont" id="mediaContDiv">
        {
          previewData.request_type === 102 ? <video src={previewData.request_media} width="100%" height="100%" style={{ maxHeight:'100vh' }} playsInline autoPlay muted loop onLoadedData={() => {mediaLoaded();}}></video> : <div className="bannerImg" style={{backgroundImage: "url(" + previewData.request_media + ")"}}></div>
        }
        </div>
        <span className="backLink"><Link to="/dashboard" state={{dashboardTab: previewData.status}}></Link></span>
        {
          (customizedText !== '' && Object.keys(mediaContentObj).length)?
          <p className="captionCont" style={mediaContentObj.parentStyles}>
            <span className="captionText" style={{backgroundColor:mediaContentObj.childStyles.backgroundColor, textShadow:((mediaContentObj.childStyles.textShadow)?mediaContentObj.childStyles.textShadow:"")}} dangerouslySetInnerHTML={createHTMLContent(customizedText)}></span>
          </p>
          : 
          multiTextBoxesMediaContentArr.map((captiondata,index) =>
            <p key={index} className={`captionCont ${(updateTop && captiondata.text != "")?'':'hideCaptions'}`} style={captiondata.parentStyles}>
              <span className="captionText" style={{backgroundColor:captiondata.childStyles.backgroundColor, textShadow:((captiondata.childStyles.textShadow)?captiondata.childStyles.textShadow:"")}} dangerouslySetInnerHTML={createHTMLContent(captiondata.text)}></span>
            </p>
          )
        }
        { userRole != 2 &&
            <div className="bottomBtns" style={{display: (previewData.status === 1)?'block':'none'}}>
              <button id="clientAppOrderRejectBtn" className="Admin-button reject" onClick={() => showRejectionView()}>Reject <span></span></button>
              <button id="clientAppOrderApproveBtn" className="Admin-button approve" onClick={() => changeStatus(previewId,2)}>Approve <span></span></button>
            </div>
        }
        {errormsg && !rejectionState && 
          <div className='errorMsg'>{errormsg}</div>
        }
        {rejectionState ?
          <div className='filterOverlayPreview'>
            <div className='rejectionCont'>
              <button className='closeBtn' onClick={(e) => setRejectionState(false)}>X</button>
              <strong>Reason for Rejection</strong>
              <div className="rejectOptionsCont">
                <span><input id="reason1" type="radio" name="gender" value="Profanity" onChange={(e) => setReason(e.target.value)} checked={(reason === 'Profanity')?'checked':''} /> <label htmlFor="reason1">Profanity</label></span>
                <span><input id="reason2" type="radio" name="gender" value="Inappropriate" onChange={(e) => setReason(e.target.value)} checked={(reason === 'Inappropriate')?'checked':''} /> <label htmlFor="reason2">Inappropriate</label></span>
                <span><input id="reason3" type="radio" name="gender" value="Other" onChange={(e) => setReason(e.target.value)} checked={(reason === 'Other')?'checked':''} /> <label htmlFor="reason3">Other</label></span>
              </div>
              <input type="text" className={(reasonError)?"reasonError":""} value={otherReasonText} onChange={(e) => setOtherReasonText(e.target.value)} placeholder='Reason...' />
              <div className='rejectionContBtns'>
                <button id="clientAppRejectionSubmitBtn" disabled={(reason === '' || (reason === 'Other' && otherReasonText === '')) ? "disabled" : ""} onClick={() => submitRejection()}>Submit</button>
              </div>
              {errormsg && 
                <div className='errorMsg' style={{width:'auto'}}>{errormsg}</div>
              }
            </div>
          </div>
          : 
          <div></div>
        }
      </div>
      :
      <Login />
      }
    </>
  );
}