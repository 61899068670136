import axios from 'axios';
import { useEffect, useState } from 'react';
import { Signin } from '../signin/Signin';
import {Link, useSearchParams, useNavigate} from 'react-router-dom';
import logo from '../../images/logo.png';
import paymentsuccess from '../../images/big_tick.png';
import '../paymentsuccess/PaymentSuccess.css';

export const PaymentSuccess = () => {
  console.log("In Payment Success page");
  const navigate = useNavigate();
  const usertoken = localStorage.getItem('appusertoken');
  const [queryParams] = useSearchParams();
  const bid = queryParams.get('bid');
  const pi = queryParams.get('payment_intent');
  const [status, setStatus] = useState(0);
  const [errormsgcode, setErrorMessage] = useState('');

  useEffect(() => {
    if(bid){
      const tokenAPI = localStorage.getItem('appusertoken');
      let checkBookingApi = process.env.REACT_APP_API_ENDPOINT+"client-app/get-booking-detail/"+bid+"?v="+Math.random();
      const headers = {
        'Content-Type': 'application/json',
        'Token': tokenAPI
      }
      axios.get(checkBookingApi, {
        headers: headers
      })
      .then(res => {
        if(res.status === 200){
          if(res.data.request_detail){
            if(res.data.request_detail.RequestStatus >= 1){
              navigate({ pathname: '/digital-sign'});
            }else{
              if(pi && bid){
                const tokenAPI = localStorage.getItem('appusertoken');
                let orderSuccessApi = process.env.REACT_APP_API_ENDPOINT+"client-app/complete-payment";
                const headers = {
                  'Content-Type': 'application/json',
                  'Token': tokenAPI
                }
                axios.post(orderSuccessApi, {bid:bid, pid:pi}, {
                  headers: headers
                })
                .then(res => {
                  if(res.status === 200){
                    setStatus(1);
                  }
                })
                .catch((error) => {
                  if(error.response.data.msg){
                    setErrorMessage(error.response.data.msg);
                  }else if(error.response.status === 401){
                    localStorage.clear();
                    navigate({ pathname: '/' });
                  }
                });
              }
            }
          }
        }
      })
      .catch((error) => {
        if(error.response.status === 401){
          localStorage.clear();
          navigate({ pathname: '/' });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
    { usertoken ?
        <div className="App-page">
          <img src={logo} className="App-logo" alt="logo" /><br/><br/>
          <span className="gridLink"><Link to="/digital-sign"></Link></span>
          { status || (!pi && !bid) ?
            <div className="outerCont successCont">
              <img src={paymentsuccess} alt="Successful" width="60%"/><br/>
              <h4>Order successful!</h4>
              <p>Your sign is being reviewed and will come out with your next bottle order. Please check your texts/email for progress updates.</p>
            </div>
          : errormsgcode !== '' &&
            <div className="outerCont successCont">
              <br/><br/><br/><br/>
              <h4>{errormsgcode}</h4>
          </div>
          }
        </div>
      :
      <Signin/>
    }
    </>
  );
}