import axios from 'axios';
import { useState, useEffect } from 'react';
import {Link, useParams, createSearchParams, useNavigate} from 'react-router-dom';
import logo from '../../images/logo.png';
import '../orderstatus/OrderStatus.css'
import { Signin } from '../signin/Signin';

export const OrderStatus = () => {
  console.log("In Order Status page");

  const navigate = useNavigate();
  const usertoken = localStorage.getItem('appusertoken');
  let params = useParams();
  const [orderData, setOrderData] = useState(null);

  useEffect(() => {
    const tokenAPI = localStorage.getItem('appusertoken');
    let orderDetailsApi = process.env.REACT_APP_API_ENDPOINT+"client-app/get-rejected-order-detail/"+params.id;
    const headers = {
      'Content-Type': 'application/json',
      'Token': tokenAPI
    }
    axios.get(orderDetailsApi, {
      headers: headers
    })
    .then(res => {
      if(res.status === 200){
        if(res.data){
          setOrderData(res.data.rejected_order_details);
        }
      }
    })
    .catch((error) => {
      if(error.response.status === 401){
        localStorage.clear();
        navigate({ pathname: '/' });
      }
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const customizeBtnClicked = (e) => {
      navigate({
        pathname: '/customize',
        search: createSearchParams({
          mo: "",
          c: orderData.BookingDetails.CustomMedia,
          bid: orderData.BookingDetails.ID,
          mode: "edit"
        }).toString()
      });
  }

  return (
    <>
    {usertoken && orderData? 
      <div className="App-page">
          <section className="topSection">
            <img src={logo} className="App-logo-medium" alt="logo" /><br/>
            <span className="gridLink"><Link to="/digital-sign"></Link></span>
          </section>
            <section className="bottomSection orderStatusCont"><br/><br/><br/>
              <p>Sorry, your order <br/>has been rejected.</p><br/>
              <p>Reason:<br/>
                <label>{orderData.RejectionDetails.rejected_reason_title} <br/><br/>
                <small>{orderData.RejectionDetails.rejected_reason_desc}</small></label>
              </p><br/><br/>
              <p>Please edit your order and try again</p><br/>
              <button id="userRejectedCustomizeBtn" className="App-button" onClick={customizeBtnClicked}>Customize</button>
            </section>
      </div>
      :
      <Signin/>
    }
    </>
  );
}