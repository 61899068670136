// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.signBoxListCont{
  width: 100%;
  overflow-y: auto;
  height: 45vh;
}

.signBoxSmall{
  width: 30%;
  float: left;
  height: 14vh;
  margin: 1% 1.5%;
}

.signBoxSmall div{
  font-size: 13px;
  padding: 5px;
  font-weight: 500;
}

.signBoxSmall div span{
  display: inline-block;
  vertical-align: top;
}

.signBoxSmall .thumbImage{
  height: 9vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border:1px solid #DEB215;
}`, "",{"version":3,"sources":["webpack://./src/App/pages/digitalsignlist/DigitalSignList.css"],"names":[],"mappings":";AACA;EACE,WAAW;EACX,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,UAAU;EACV,WAAW;EACX,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,sBAAsB;EACtB,4BAA4B;EAC5B,kCAAkC;EAClC,wBAAwB;AAC1B","sourcesContent":["\n.signBoxListCont{\n  width: 100%;\n  overflow-y: auto;\n  height: 45vh;\n}\n\n.signBoxSmall{\n  width: 30%;\n  float: left;\n  height: 14vh;\n  margin: 1% 1.5%;\n}\n\n.signBoxSmall div{\n  font-size: 13px;\n  padding: 5px;\n  font-weight: 500;\n}\n\n.signBoxSmall div span{\n  display: inline-block;\n  vertical-align: top;\n}\n\n.signBoxSmall .thumbImage{\n  height: 9vh;\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: center center;\n  border:1px solid #DEB215;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
