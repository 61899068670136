// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/grid-icon.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.orderStatusCont p{
  color: #deb215;
  font-size: 16px;
  line-height: 20px;
}

.orderStatusCont button{
  width: 150px;
}

.gridLink{
  position: absolute;
  top:30px;
  left: 30px;
  width: 25px;
  height: 25px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat;
  background-size: contain;
}

.gridLink a{
  width: 25px;
  height: 25px;
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/App/pages/orderstatus/OrderStatus.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,WAAW;EACX,YAAY;EACZ,6DAAuD;EACvD,wBAAwB;AAC1B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;AAChB","sourcesContent":[".orderStatusCont p{\n  color: #deb215;\n  font-size: 16px;\n  line-height: 20px;\n}\n\n.orderStatusCont button{\n  width: 150px;\n}\n\n.gridLink{\n  position: absolute;\n  top:30px;\n  left: 30px;\n  width: 25px;\n  height: 25px;\n  background: url('../../images/grid-icon.png') no-repeat;\n  background-size: contain;\n}\n\n.gridLink a{\n  width: 25px;\n  height: 25px;\n  display: block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
