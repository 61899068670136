import axios from 'axios';
import { useState, useEffect } from 'react';
import {useNavigate, Link, useSearchParams} from 'react-router-dom';
import { Signin } from '../signin/Signin';
import logo from '../../images/logo.png';

export const ConfirmTable = () => {
  console.log("In Confirm Table page");

  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const tableID = queryParams.get('tableID');
  const usertoken = localStorage.getItem('appusertoken');
  const [tableData, setTableData] = useState([]);
  const [apiErrorMsg, setApiErrorMsg] = useState('');

  useEffect(() => {
    const tokenAPI = localStorage.getItem('appusertoken');
    let tableApi = process.env.REACT_APP_API_ENDPOINT+"client-app/club-table-info/"+tableID;
    const headers = {
      'Content-Type': 'application/json',
      'Token': tokenAPI
    }
    axios.get(tableApi, {
      headers: headers
    })
    .then(res => {
      if(res.status === 200){
        if(res.data.table_data){
          setTableData(res.data.table_data);
        }
      }
    })
    .catch((error) => {
      if(error.response.data.msg){
        setApiErrorMsg(error.response.data.msg);
      }else if(error.response.status === 401){
        localStorage.clear();
        navigate({ pathname: '/' });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const confirm = (e) => {
    localStorage.setItem('appusertblid',tableID);
    localStorage.setItem('appcid',tableData.club_id);
    navigate({ pathname: '/digital-sign'});
  }

  return (
    <>
    { usertoken ?
      <div className="App-page">
          <section className="topSection">
            <img src={logo} className="App-logo-medium" alt="logo" /><br/>
            <span className="backLink"><Link to="/scan"></Link></span>
          </section>
          { (tableData.table_number && apiErrorMsg === '') ?
            <section className="bottomSection">
              <br/><br/><br/>
              <img src={tableData.club_logo} className="App-logo-medium imgHAuto" alt="logo" />
              <br/><br/><br/><br/><br/>
              <p>
                <strong>Table # {tableData.table_number}</strong><br/>
                <small>City: {tableData.club_city}</small>
              </p>
              <br/><br/>
                <button id="userConfirmTableBtn" className="App-button" onClick={confirm}>Confirm</button>
              <br/>
            </section>
            :
            (apiErrorMsg !== '') ? 
            <section className="bottomSection">
              <br/><br/><br/>
              <p>{apiErrorMsg}</p>
              <br/>
            </section>
            :
            <section className="bottomSection"></section>
          }
      </div>
      :
      <Signin/>
    }
    </>
  );
}