// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: 'Gotham', 'Helvetica Neue', sans-serif;
  background-color: black;
}

button, input {
  font-family: 'Gotham', 'Helvetica Neue', sans-serif;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,mDAAmD;EACnD,uBAAuB;AACzB;;AAEA;EACE,mDAAmD;AACrD","sourcesContent":["body {\n  margin: 0;\n  font-family: 'Gotham', 'Helvetica Neue', sans-serif;\n  background-color: black;\n}\n\nbutton, input {\n  font-family: 'Gotham', 'Helvetica Neue', sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
