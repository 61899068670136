// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App-logo {
  height: 40vh;
  pointer-events: none;
}

.App-page form label, .App-page label{
  color:#d0d0d0;
  text-align: center;
  display: block;
  margin-bottom:7px;
}

.App-page form input{
  width: 100%;
  background:transparent;
  border:none;
  border-bottom:1px solid #f2f2f2;
  font-size:18px;
  line-height:28px;
  outline:none;
  margin-top: 10px;
  color:#ffffff;
  padding:0 5px;
  box-sizing: border-box;
  text-align: center;
}

.App-page .error{
  color:red;
  font-size: 16px;
  margin-bottom:10px;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/App/pages/signin/Signin.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,sBAAsB;EACtB,WAAW;EACX,+BAA+B;EAC/B,cAAc;EACd,gBAAgB;EAChB,YAAY;EACZ,gBAAgB;EAChB,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,SAAS;EACT,eAAe;EACf,kBAAkB;EAClB,kBAAkB;AACpB","sourcesContent":[".App-logo {\n  height: 40vh;\n  pointer-events: none;\n}\n\n.App-page form label, .App-page label{\n  color:#d0d0d0;\n  text-align: center;\n  display: block;\n  margin-bottom:7px;\n}\n\n.App-page form input{\n  width: 100%;\n  background:transparent;\n  border:none;\n  border-bottom:1px solid #f2f2f2;\n  font-size:18px;\n  line-height:28px;\n  outline:none;\n  margin-top: 10px;\n  color:#ffffff;\n  padding:0 5px;\n  box-sizing: border-box;\n  text-align: center;\n}\n\n.App-page .error{\n  color:red;\n  font-size: 16px;\n  margin-bottom:10px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
